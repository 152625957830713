import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from "react-router-dom";
import { useAuth } from '../../context/AuthContext';
import { Typography }from "@mui/material";
import {
  Box,  
  Button,
  Grid,
  Stack
} from "@mui/material";






export function RegisterPage() {
  const { register, handleSubmit, formState:{ errors } } = useForm();
  const {signup, isAuthenticated, errors: registerErrors } = useAuth();
  const navigate = useNavigate();
  
  
  const onSubmit = handleSubmit (async (values) => {
    signup(values);
    navigate('/home');
  });
  
  

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="flex-start"
      marginBottom={20}
    >
      { registerErrors.map((error, i) => (
          <div key ={i}>
            {error}
          </div>
        ))}

          <Typography variant="h3" color="primary"
                      >Register</Typography>  

          <form onSubmit={onSubmit}>
            <Box 
                sx={{ width: '100%' }}
                marginTop={2}
                >
              <Stack spacing={2}>
                <input type="text" 
                    {...register('username', {required: true})}
                    placeholder='username'
                    autoFocus
                    />    
                    {errors.username?.message && (
                      <Typography variant="h3" color="red"
                      >{errors.username?.message}</Typography>            
                    )}   
                <input type='email' 
                    {...register('email', {required: true})}
                    placeholder='email'
                    />
                    {errors.email?.message && (
                        <Typography variant="h3" color="white"
                        >{errors.email?.message}</Typography>            
                      )}
                <input type='password' 
                      {...register('password', {required: true})}
                      placeholder='password'
                    />
                    {errors.password?.message && (
                        <Typography variant="h3" color="white"
                        >{errors.password?.message}</Typography>           
                      )}
                <input type='text' 
                      {...register('role', {required: true})}
                      placeholder='role'
                    />
                    {errors.role?.message && (
                        <Typography variant="h3" color="red"
                        >{errors.role?.message}</Typography>            
                      )}
                <Button 
                  type='submit'
                  variant="outlined"
                  sx={{ mx: 2, my: 2 }}
                  >Login</Button>                 
              </Stack>
            </Box>
            
            
            
            
            
            </form>   

         

    </Grid>
    
  )
}


