import React from "react";
import { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import { selectPost } from '../../../features/post/postSlide';
import { selectCuenta } from '../../../features/cuentas/cuentaSlide';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getVistasRequest } from '../../../api/vista';
import { getCuentasRequest } from "../../../api/cuenta";
import EastIcon from '@mui/icons-material/East';

import CircularProgress from '@mui/material/CircularProgress';
import {
  Typography,
  Box,
  Button,
  Chip,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,  
} from "@mui/material";

export const HomeTable = ({tablas}) => {

  const navigate = useNavigate();

  return (
    <Table
      aria-label="simple table"
      sx={{
        mt: 3,
        whiteSpace: "nowrap",
      }}
    >
      <TableHead>
        <TableRow>
          <TableCell><Typography color="textSecondary" sx={{
                fontSize: "14px", fontWeight: "800"}}>Ir
            </Typography></TableCell>
          <TableCell><Typography color="textSecondary" sx={{
                fontSize: "14px", fontWeight: "800"}}>Cuenta
            </Typography></TableCell>
          <TableCell><Typography color="textSecondary" sx={{
                fontSize: "14px", fontWeight: "800"}}>Nombre
            </Typography></TableCell>
          <TableCell><Typography color="textSecondary" sx={{
                fontSize: "14px", fontWeight: "800"}}>Vbat
            </Typography></TableCell>
          <TableCell><Typography color="textSecondary" sx={{
                fontSize: "14px", fontWeight: "800"}}>Status
            </Typography></TableCell>
          <TableCell><Typography color="textSecondary" sx={{
                fontSize: "14px", fontWeight: "800"}}>Carga
            </Typography></TableCell>
          <TableCell><Typography color="textSecondary" sx={{
                fontSize: "14px", fontWeight: "800"}}>Corr
            </Typography></TableCell>
          <TableCell><Typography color="textSecondary" sx={{
                fontSize: "14px", fontWeight: "800"}}>Tipo
            </Typography></TableCell>
          <TableCell><Typography color="textSecondary" sx={{
                fontSize: "14px", fontWeight: "800"}}>Tiempo
            </Typography></TableCell>
          <TableCell><Typography color="textSecondary" sx={{
                fontSize: "14px", fontWeight: "800"}}>Veces
            </Typography></TableCell>
          <TableCell><Typography color="textSecondary" sx={{
                fontSize: "14px", fontWeight: "800"}}>Error
            </Typography></TableCell>
          <TableCell><Typography color="textSecondary" sx={{
                fontSize: "14px", fontWeight: "800"}}>Online
            </Typography></TableCell>          
        </TableRow>
      </TableHead> 
      <TableBody>
        {tablas.map((x) => (
          <TableRow key={x.cuentaTabla}>
            <TableCell>
              <IconButton aria-label="delete" onClick={()=> navigate(`/dash/${x.cuentaId}`)}>
                <EastIcon />
              </IconButton>              
            </TableCell>
            <TableCell><Typography sx={{
                  fontSize: "10px",
                  fontWeight: "500",
                }}
              >
                {x.cuentaTabla}
              </Typography></TableCell>
            <TableCell><Typography sx={{
                  fontSize: "10px",
                  fontWeight: "500",
                }}
              >
                {x.nombreTabla}
              </Typography></TableCell>  
            <TableCell><Typography sx={{
                  fontSize: "10px",
                  fontWeight: "500",
                }}
              >
                {x.vbat}
              </Typography></TableCell>  
            <TableCell>
            <Chip
                sx={{
                  pl: "4px",
                  pr: "4px",
                  backgroundColor: x.backcells,
                  color: "#1a237e",
                  fontSize: "10px",
                }}
                size="small"
                label={x.statust}
              />
              </TableCell>  
            <TableCell><Typography sx={{
                  fontSize: "10px",
                  fontWeight: "500",
                }}
              >
                {x.charg}
              </Typography></TableCell>  
            <TableCell><Typography sx={{
                  fontSize: "10px",
                  fontWeight: "500",
                }}
              >
                {x.ic}
              </Typography></TableCell>  
            <TableCell><Typography sx={{
                  fontSize: "10px",
                  fontWeight: "500",
                }}
              >
                {x.type}
              </Typography></TableCell>  
            <TableCell><Typography sx={{
                  fontSize: "10px",
                  fontWeight: "500",
                }}
              >
                {x.c_seg}
              </Typography></TableCell>  
            <TableCell><Typography sx={{
                  fontSize: "10px",
                  fontWeight: "500",
                }}
              >
                {x.c_marcha}
              </Typography></TableCell> 
            <TableCell><Typography sx={{
                  fontSize: "10px",
                  fontWeight: "500",
                }}
              >
                {x.error}
              </Typography></TableCell>  
            <TableCell>
              <Chip
                  sx={{
                    pl: "4px",
                    pr: "4px",
                    backgroundColor: x.difcells,
                    color: "#1a237e",
                    fontSize: "10px",
                  }}
                  size="small"
                  label={x.fecha}
                />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};


