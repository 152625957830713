import React from 'react';
import { useEffect, useState } from "react";
import { useVariables } from '../../context/VariablesContext';
import { useNavigate, useParams } from "react-router-dom";
import { Box, Button, Card, Grid, TextField, useColorScheme, useTheme, Typography, MenuItem } from "@mui/material";
import { 
    GridRowModes,
    DataGrid, 
    GridToolbar,
    GridToolbarContainer, 
    GridActionsCellItem,
    GridRowEditStopReasons,
  } from '@mui/x-data-grid';
  import { DataGridPro } from '@mui/x-data-grid-pro';
  import { EditVariable } from './EditVariable';
  import AddAlertOutlinedIcon from '@mui/icons-material/AddAlertOutlined';
  import { useSelector } from 'react-redux';
  import { selectVariable } from '../../features/variables/variableSlide';
  import { useAuth } from '../../context/AuthContext';
  import { KeyPc } from '../key/KeyPc';
  

export function TablaVariable() {

  const [rowId, setRowId] = useState(null);
  const variable = useSelector(selectVariable)
  const {isAuthenticated, logout, user } = useAuth();
  const navigate = useNavigate(); 
  //console.log(variable)

  const variable1 = variable.filter(x => (x.definicion !== "min"  ))

  //console.log(user)
  

  const columns = [    
    {
      field: "createdAt",
      headerName: "Fecha",
      width:110,
      valueFormatter: params => new Date(params?.value).toLocaleString('en-GB'),       
    },
    {
      field: "definicion",
      headerName: "Definicion",
      width:150,     
    },
    {
      field: "valor",
      headerName: "Valor",
      width:150,     
    },    
    {
      field: "texto",
      headerName: "Descripcion",
      width:150,     
    },    
    {
      field: "accion",
      headerName: "Cambiar",
      type:'action',
      align:"center",
      renderCell: (params)=><EditVariable
        {...{ params, rowId, setRowId }}
        />,
        width:100,      
    },  
  ]

  const [sortModel, setSortModel] = useState([
    {
      field: 'createdAt',
      sort: 'desc',
    },
  ]);

  //console.log(user.role)

  return (
    <Grid
      container
      direction="row"
      sx={{
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {(user.role === 'admi' )? 

        
          <Grid
            container
            direction="row"
            sx={{
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <Box  
              sx={{ height: 600, width: 200 }}
            >
              <KeyPc/>
            </Box>
            <Box
                marginX={5}
                marginY={1}
                sx={{ height: 600, width: 640 }}
            >
                <DataGridPro
                    rows={variable1} 
                    columns={columns}  
                    rowHeight={18}
                    getRowId={(row) => row._id} 
                    sortModel={sortModel}                        
                    onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
                />
            </Box>
          </Grid>
      : 
          <Box
          marginX={5}
          marginY={1}
          sx={{ height: 600, width: 640 }}
          >
            <DataGridPro
                rows={variable1} 
                columns={columns}  
                rowHeight={18}
                getRowId={(row) => row._id} 
                sortModel={sortModel}                        
                onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
            />
          </Box>      
      }

    </Grid>





    
  )
}


