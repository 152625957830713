import axios from './axios';

export const getPotsRequest = () => axios.get('/posts', {  maxContentLength: 10 })    

export const getPotsRequestlimit = () => axios.get('/postsl',{ params: { _id: '65c4d8cce0e5527559e5f277' } })
    
export const getPostRequest = (id) => axios.get(`/posts/${id}`);

export const updatePostRequest = (id, post) => axios.put(`/posts/${id}`, post);

export const postQueryPostRequest = (value) => axios.post(`/postsQ`, {value: value});

export const getPostsDashRequest = (id) => axios.get(`/postsDash/${id}`);





