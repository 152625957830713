import { useEffect } from "react";
import { createContext, useContext, useState } from "react";
import { registerRequest, loginRequest, verityTokenRequest, getUsersRequest  } from '../api/auth';

import Cookies from 'js-cookie';

const AuthContext = createContext()

export const useAuth = () => {
  const context = useContext(AuthContext)
  if (!context) throw new Error("useAuth must be used within a AuthProvider");
  return context;
};

export const AuthProvider = ({children}) => {
  
  const [ user, setUser ] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [ errors, setErrors ] = useState([]);
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);

  const signup = async (user) => {
    try {
      const res = await registerRequest(user); 
      //console.log('reg page:', res.data);
      setUser(res.data);
      setIsAuthenticated(true);
    } catch (error) {
      //console.log('contex auth:', error.response.data);
      if(Array.isArray(error.response.data)) {
        return setErrors(error.response.data);
      }
      setErrors([error.response.data.message]);
      setErrors('error context aut:', error.response.data.message);
      setErrors(error.response.data)
    }
  } 

  const signin = async (user) => {
    try {
      const res = await loginRequest(user);
      setUser(res.data);
      setIsAuthenticated(true);
      //console.log('aute signin:', res.data)
      //console.log('isauth signin:', isAuthenticated)
      //console.log('isauth signin:', res.data)
    } catch (error) {
      console.log('isauth signin:', error);
      if(Array.isArray(error.response.data)){
        return setErrors(error.response.data);
      }
      setErrors([error.response.data.message] )      
      //setErrors(error.response.data.message);
    }
  };

  const logout = () => {
    Cookies.remove("token");
    setIsAuthenticated(false);
    setUser(null);
  }

  // clear errors after 5 seconds
  useEffect(() => {
    if (errors.length > 0) {
      const timer = setTimeout(() => {
        setErrors([]);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [errors]);

  useEffect(() => {
    const checkLogin = async () => {
      const cookies = Cookies.get();
      if (!cookies.token) {
        setIsAuthenticated(false);
        setLoading(false);
        return setUser(null);
      }
          try {
            const res = await verityTokenRequest(cookies.token);
            //console.log('auth cookies:', res);
            if (!res.data) {
              setIsAuthenticated(false)
              setLoading(false);
              return;
            }
            setIsAuthenticated(true);
            setUser(res.data);
            setLoading(false);
          } catch (error) {
            setIsAuthenticated(false);
            setLoading(false);
            setUser(null);
          }
    };
    checkLogin();
  }, []);  

  const getUsers = async () => {
    try {
      const res = await getUsersRequest()
      //console.log('contx gets  cuenta:', res)
      setUsers(res.data)
    } catch (error) {
      console.log('error getUsers:', error);
    }
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        signup,
        isAuthenticated,
        errors,
        loading,
        signin, 
        logout,
        getUsers,
        users        
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};