import React from 'react';
import axios from 'axios';
import { useEffect, useState } from "react";
import { getPotsRequest } from "../api/post";
import { getCuentasRequest } from "../api/cuenta";
import { getJobsRequest } from "../api/job";
import { getVariablesRequest } from "../api/variable";
import { getEnvRequest, getTopicRequest } from "../api/env";
import { useSelector, useDispatch } from 'react-redux';
import { addCont } from "../features/cuentas/cuentaSlide";
import { addJob } from "../features/jobs/jobSlide";
import { addVariable } from "../features/variables/variableSlide";
import { addPost } from "../features/post/postSlide";
import { addAlarm } from "../features/alarms/alarmSlide";
import { useAuth } from "../context/AuthContext";
import { Link, useNavigate, useParams } from 'react-router-dom';
import { AppBar, Toolbar, Typography, styled } from '@mui/material'
import {
  Badge,
  Box,
  Button,
  Container, 
  Grid, 
  IconButton,
  Stack,
  Switch
} from "@mui/material";
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import PhoneAndroidOutlinedIcon from '@mui/icons-material/PhoneAndroidOutlined';
import { useVariables } from "../context/VariablesContext";
import { selectPost } from '../features/post/postSlide';
import { postQueryPostRequest } from "../api/post";
import { selectCuenta } from '../features/cuentas/cuentaSlide';
import { getVistaRequest, updateVistaRequest } from '../api/vista';

const StyledToolbar = styled(Toolbar) ({
  disply:'flex',
  justifyContent: 'space-between',
})

const Icons = styled(Box)(({ theme }) => ({
  display: "none",
  alignItems: "center",
  gap: "10px",
  [theme.breakpoints.up("sm")]: {
    display: "flex",
  },
}));

export function Navbar() {

  const {isAuthenticated, logout, user } = useAuth();
  const navigate = useNavigate(); 
  const params = useParams();
  const dispatch = useDispatch();   
  var cuentas = useSelector(selectCuenta)
  const [post, setPosts] = useState([]);
  const posts = useSelector(selectPost) 
  const { vbateria, variable, getVariables } = useVariables([]);
  const [ count, setCount] = useState(0);
  const [ alarmRed, setalarmRed] = useState([]);
  const [ environments, setEnvironments] = useState({
    chatId: "",
    message: "",
    authToken: "",
  });
  
  // cargo todo menos post - Redux
  useEffect(() => {
    const timer = setInterval( async () => {
      //const posts = await getPotsRequest();  
      const resc = await getCuentasRequest();
      const resj = await getJobsRequest();
      const resv = await getVariablesRequest();
      //console.log('compon', resv.data) 
      dispatch(addCont(resc.data));
      dispatch(addJob(resj.data));       
      dispatch(addVariable(resv.data)); 
      const vbateria = await getVariables();            
      //console.log(resEnv.data);    
    }, 20000);
    return () => clearInterval(timer);    
  }, []); 
  
  
  // cargo post - Redux
  useEffect(() => {
    const timer = setInterval( async () => {
      const posts = await getPotsRequest();        
      const postsLenght = (posts.data.length)
      //console.log(postsLenght)
      const postReducido = posts.data.slice((postsLenght,postsLenght-2000))
      //console.log(postReducido)
      dispatch(addPost(postReducido));       
    }, 40000);
    return () => clearInterval(timer);    
  }, []); 


  const Vbat = vbateria
  const emer = posts.filter(x => (x.topicevento === "vbat"  ))  
  const event1 = emer.filter(x => (x.message < Vbat ))
  const event2 = event1.filter(x => (x.estado === "alarma"  ))
  const event1lenght = (event2.length)

  useEffect(() => {
    (async () => {      
        const resEnv = await getEnvRequest();
        const resVar = await getVariablesRequest();  
        const resTopic = await getTopicRequest();       
        //console.log(resVar.data)
        const telefono1 = resVar.data.filter(x => (x.definicion === "TelegrafId"  ))
        const telefono11 = telefono1[0].valor
        
        //console.log(resTopic.data)
        //const mess1 = event2[0].message
        //const mess2 = '🚨 ALARMA ' + mess + '  volatje ' + mess1
        //console.log(telefono11, posts)

        const mess2 = '🚨 ALARMA ' + '  volatje ' + 'Bajo'
        setEnvironments({
          message: mess2,
          authToken: resEnv.data,
          chatId: telefono11
        })
    })();
  }, []);
  //console.log(environments)



 /*
  //rutina Telegram
  const botSend = async (messages) => {
    const url = `https://api.telegram.org/bot${environments.authToken}/sendMessage`;
    const payload = {
        chat_id: environments.chatId,
        text: environments.message,
        // https://core.telegram.org/bots/api#html-style
        parse_mode: 'html' // html | markdown
    }    
  return  axios.post(url, payload)
}
if ( event1lenght > count ) {
  botSend();
  //console.log('ALARM')
  setCount(event1lenght)      
  //console.log('alarma', count, event1lenght)
} 
if ( count > event1lenght ) {
  setCount(event1lenght)
  //console.log('sin alarma', count, event1lenght)
}
// fin 
*/

  const handleLogOut = () => {
    logout();               
  }  
  
  /*
  const cut = 300
  const timeCut = cut * 1000;
  useEffect(() => {
    const timer = setInterval( () => {
      handleLogOut();
    }, timeCut);
    return () => clearInterval(timer);
  }, []); 
  */

  // rutina de limpieza de base de datos 
    let ms = new Date();
    let day = ms.getDate();
    let month = ms.getMonth();
    let year = ms.getFullYear();
    let date = year + '-' + month + '-' + day
    //console.log(date)
    const [hists, setHits] = useState([]);

    useEffect(() => {
      const timer = setInterval( async () => {
        const res = await postQueryPostRequest(date);
        setHits(res.data) 
        }, 20000);
      return () => clearInterval(timer);    
    }, []);     
    //console.log(hists)    

 
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <Typography variant='p' sx={{ flexGrow: 6 }}>{isAuthenticated ?  <p>{user.username} </p> : null }</Typography>
          <Box sx={{ flexGrow: 1 }}>
            <IconButton                  
                  onClick={()=> navigate(`/mon`)}           
                >
                <Badge badgeContent={ event1lenght } color="error">
                  <NotificationsNoneOutlinedIcon />
                </Badge>                  
            </IconButton>  
            
            <IconButton onClick={()=> navigate(`/hmov`)}>
                <PhoneAndroidOutlinedIcon />
            </IconButton>  
                 

            { isAuthenticated   ? ((user.role === 'admi') ? <Button color="inherit" href='/reg'>
              Register </Button>:null): null }               

            {!isAuthenticated ? <Button color="inherit" href='/login'>Login</Button>   : 
                      <Button color="inherit" onClick={() => { handleLogOut()}}>Logout</Button> } 
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  )
}

