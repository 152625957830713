import React, { useEffect, useState } from 'react';
import { Box, Button, TextField,  useColorScheme, useTheme, Typography, MenuItem } from "@mui/material";

export function DelItem() {

  const delToken = () => {
    localStorage.removeItem('tokenPc'); 
  }

  return (
    <Box>
       <Button 
              onClick={()=> delToken()}
              >Del Token</Button>
            <br/>
        Tokenn borrado
    </Box>
  )
}


