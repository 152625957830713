import React from "react";

import Chart from "react-apexcharts";

const Corriente = ({i1m, xvolt, volt}) => {
  
  const series = [
    {
      name: "Vbat",
      data: volt
    },    
    {
      name: "Corr",
      data: i1m
    },       
  ];

  const options = {
    chart: {
        height: 240,
        type: 'area',
        toolbar: {    
            show: false,
          }, 
      },
      dataLabels: {
        enabled: false
      },       
      stroke: {
        curve: 'smooth'
      },
      title: {
        text: '',
        align: 'left',
        style: {
          fontSize: '14px'
        },
        legend: {
          position: 'right',
          horizontalAlign: 'right',
          offsetX: -10
        }
        
      },  
      xaxis: {
        categories: xvolt,
      },
      yaxis: {  
        min : 0,
        max: 30,
      },
      legend: {
        position: 'top',
        horizontalAlign: 'right',
        offsetX: -10
      },
      
      grid: {
        row: {
          colors: ['#f3f3f3', 'transparent'], 
          opacity: 0.5
        },
      }         
  }  
    return <Chart options={options} series={series} type="area" height={200} width={600}/>;
};
export default Corriente;
