import React from 'react';
import { useSelector } from 'react-redux';
import { selectPost } from '../../features/post/postSlide';
import { useEffect, useState } from "react";
import { Box, Button, Card, Grid, TextField, useColorScheme, useTheme, Typography, MenuItem, Paper } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';  
import { getHistorysRequest, getHistoryEvent } from '../../api/history';
import { 
    GridRowModes,
    DataGrid, 
    GridToolbar,
    GridToolbarContainer, 
    GridActionsCellItem,
    GridRowEditStopReasons,
  } from '@mui/x-data-grid';
  import { DataGridPro } from '@mui/x-data-grid-pro'; 
  
  export function HistoryPage() {

    const [his, setHis] = useState([]);
    const [rango, setRango] = useState('0000')
    const [carga, setCarga] = useState(false)
    
    const handleChangea = event => {
      setRango(event.target.value)
    }

    const push = async () => {
        setCarga(true)
        const historia = await getHistoryEvent(rango);      
        setHis(historia.data)

        //console.log(historia.data.length)
        setCarga(false)
                
    }    

    console.log(his)

    const columns = [    
      {
        field: "createdAt",
        headerName: "Fecha",
        valueFormatter: params => new Date(params?.value).toLocaleString('en-GB'),
        width:170,      
      },
      {
        field: "topiccuenta",
        headerName: "# orden",
        width:68,  
        align:"center",   
      },           
      
      {
        field: "type",
        headerName: "tipo",
        width:50,  
        align:"left",     
      },  
      {
        field: "vbat",
        headerName: "Bateria",
        width:70,  
        align:"left",     
      }, 
      {
        field: "status",
        headerName: "Estado",
        width:150,  
        align:"center",     
      },   
      {
        field: "sec",
        headerName: "Sec",
        width:70,  
        align:"left",     
      },
      {
        field: "charg",
        headerName: "Carga",
        width:150,  
        align:"left",     
      }, 
      {
        field: "error",
        headerName: "Error",
        width:150,  
        align:"left",     
      }, 
      {
        field: "ic",
        headerName: "Corr",
        width:50,  
        align:"center",     
      }, 
      {
        field: "c_marcha",
        headerName: "Veces",
        width:50,  
        align:"center",     
      }, 
      {
        field: "c_seg",
        headerName: "Seg",
        width:80,  
        align:"center",     
      },      
    ]

    const [sortModel, setSortModel] = useState([
      {
        field: 'createdAt',
        sort: 'desc',
      },
    ]);

    return (
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="flex-start"
        marginLeft={2}
        
        >
          <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"             
              >
                <Box
                  marginLeft={3}
                  marginTop={2}
                >
                  <Typography color="textSecondary" sx={{
                    fontSize: "18px", fontWeight: "800"}}>Numero de eventos: {his.length}
                  </Typography>                  
                </Box>
                <Box
                  marginLeft={3}
                  width={80}
                >
                  <TextField 
                    hiddenLabel
                    id="filled-hidden-label-small"
                    defaultValue="Small"
                    variant="filled"
                    size="small"                  
                    value={rango} onChange={handleChangea}/>
                </Box>                
                <Box
                  marginLeft={3}
                  ><Button variant="outlined" onClick={push}>
                    Informacion historica                                      
                  </Button></Box>                  
          </Grid>
            <Paper elevation={1} square={false} >
                <Box
                    marginX={5}
                    marginY={1}
                    sx={{ height: 700, width: 1260 }}
                    >     
                    {carga? 
                    <Box
                        marginLeft={50}
                        marginTop={20}
                        paddingBottom={50}
                    >
                      <Box><Typography color="textSecondary" sx={{
                          fontSize: "18px", fontWeight: "800"}}>Sin Informacion o cargando .........</Typography></Box>
                      <Box
                        marginTop={4}
                        marginLeft={6}
                      ><CircularProgress /></Box>
                      
                                                                     
                    </Box>  
                    :<Box
                      marginX={5}
                      marginY={1}
                      sx={{ height: 700, width: 1260 }}                    
                    >
                      {/* */}
                      <DataGrid
                            rows={his} 
                            columns={columns}  
                            slots={{ toolbar: GridToolbar }}
                            rowHeight={18}
                            getRowId={(row) => row._id} 
                            sortModel={sortModel}                        
                            onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
                        />   
                        
                      </Box>}            
                </Box>
              </Paper>
    </Grid>
    )
  }
  
 
  