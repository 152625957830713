import React from 'react';
import { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import { selectPost } from '../../features/post/postSlide';
import { selectCuenta } from '../../features/cuentas/cuentaSlide';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getVistasRequest } from '../../api/vista';
import { getCuentasRequest } from "../../api/cuenta";
import { Card, CardContent, Box, Typography } from "@mui/material";

import CircularProgress from '@mui/material/CircularProgress';
import { HomeTable } from './Table/HomeTable'

export function HomePage() {

  const posts = useSelector(selectPost)
  const cuentas = useSelector(selectCuenta)     
    

  const [ tablas, setTabla] = useState([]);
  const navigate = useNavigate(); 

  const Chart = async () => {
    try {
      const cuentaVisor = (await getCuentasRequest()).data.filter(x => x.habilitado === "habi");
      const vista = (await getVistasRequest()).data;
      const datos = cuentaVisor.map((cuenta, index) => {
        const vistaTabla = vista.find(x => x.topiccuenta === cuenta.nroorden) || {}; // Filtro para cada cuenta
        const fechaActual = new Date().getTime();
  
        // Calcular fecha de actualización y diferencia de tiempo
        const fechaActualizacion = vistaTabla.updatedAt ? new Date(vistaTabla.updatedAt).toLocaleString('en-GB') : 0;
        const diferenciaTiempo = vistaTabla.updatedAt ? fechaActual - new Date(vistaTabla.updatedAt).getTime() : 0;
  
        // Establecer colores de fondo según el estado y tiempo
        const backcell = vistaTabla.status === 'ENCENDIDO' ? '#69f0ae' : 'secondary';
        const difcell = diferenciaTiempo < 1210000 ? '#69f0ae' : '#ff5722';
  
        return {
          cuentaId: cuenta._id,
          cuentaTabla: cuenta.nroorden,
          nombreTabla: cuenta.nombre,
          fecha: fechaActualizacion,
          vbat: vistaTabla.vbat || 0,
          statust: vistaTabla.status || 0,
          charg: vistaTabla.charg || 0,
          ic: vistaTabla.ic || 0,
          type: vistaTabla.type || 0,
          sec: vistaTabla.sec || 0,
          c_seg: vistaTabla.c_seg || 0,
          c_marcha: vistaTabla.c_marcha || 0,
          error: vistaTabla.error || 0,
          cr: vistaTabla.cr || 0,
          dif: diferenciaTiempo,
          backcells: backcell,
          difcells: difcell
        };
      });
  
      setTabla(datos);
    } catch (error) {
      console.error('Error en Chart:', error);
    }
  };
  
  useEffect(() => {
    const timer = setInterval(async () => {
      await Chart();
    }, 10000);
    return () => clearInterval(timer);
  }, []);
    
  const [sortModel, setSortModel] = useState([
    {
      field: 'cuentaTabla',
      sort: 'asc',
    },
  ]);

  return (
    <Box
      
    >
      <Card variant="outlined">
        <CardContent>
          
          <Box
            sx={{
              overflow: {
                xs: "auto",
                sm: "unset",
              },
            }}
          >
            <HomeTable tablas={tablas}/>
          </Box>
        </CardContent>
      </Card>
    </Box>
  )
}


