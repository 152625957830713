import React from 'react';
import { useEffect, useState } from "react";
import { 
  Box, 
  Button,
  Card, 
  CardHeader,   
  CardContent, 
  Grid,
  Typography, 
  IconButton,
  Tooltip 
} from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import { useSelector } from 'react-redux';
import { selectCuenta } from '../../features/cuentas/cuentaSlide';
import { usePosts } from '../../context/PostContext';
  



export function MonitorDialog(params) {

  const cuentas = useSelector(selectCuenta); 
  const { posts } = usePosts(); 
  
  
  const evento = params.params  

  const results = posts.filter( post => post._id === evento)
  const cuentaeven = cuentas.filter(cuenta => cuenta.nroorden === results[0].topiccuenta )
  //console.log(cuentaeven)

  return (
    <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"      >
          <box
            height={200}
            width={500}
          >
            <Typography>{cuentaeven[0].nroorden} - {cuentaeven[0].nombre}</Typography>
            <Typography>{cuentaeven[0].direccion} - Tel: {cuentaeven[0].telefono}</Typography>
            
            
          </box>
        
    </Grid>
    
    
    
  )
}


