// https://www.chartjs.org/docs/latest/charts/line.html
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectCuenta } from '../../../../features/cuentas/cuentaSlide';  
import { usePosts } from '../../../../context/PostContext';
import { useAuth } from "../../../../context/AuthContext";

import { Box, Grid, Button, Paper, Typography } from "@mui/material";
import { GaugePage } from '../Box/Graph/GaugePage';
import { LineChart } from '@mui/x-charts/LineChart';
import { getPostRequest, getPotsRequest } from "../../../../api/post";
import { selectPost } from '../../../../features/post/postSlide';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  Chart as ChartJS,
  CategoryScale,  
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { Line } from 'react-chartjs-2';
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
);

export function Grafico10() {

  const params = useParams(); 
  const posts = useSelector(selectPost);     
  const cuentas = useSelector(selectCuenta);
  const cuentaResult = cuentas.filter(x => x._id === params.id); 
  const postResult = posts.filter(x => x.topiccuenta === cuentaResult[0].nroorden)
  const [chartData, setChartData]  = useState({
    labels: "",
    datasets: [
      {
      label: "",
      data: "",
    },        
  ]
  });
  const [chartDataA, setChartDataA]  = useState({
    labels: "",
    datasets: [
      {
      label: "",
      data: "",
    },        
  ]
  });

  const Chart = async () => {      
    const postChart = await getPotsRequest();      
    const postChartData = postChart.data
         //console.log(postChartData)

    const cuentaResult = cuentas.filter(x => x._id === params.id)    
    const postResult = postChartData.filter(x => x.topiccuenta === cuentaResult[0].nroorden)  
    
    // Voltaje
    const voltR = postResult.filter(x => (x.topicevento === "vbat"  ))
    const LengthR = (voltR.length)
    const voltR2 = voltR.slice((LengthR,LengthR-10))
    
    // voltaje
    const VoltVar = postResult.filter(x => x.topicevento === 'vbat');
      const Volt = []
      const xVolt = []
      const Vfinal = VoltVar.length
      const Vinicio = 0
      for (let index = Vinicio; index < Vfinal; index++) {            
          Volt.push(VoltVar[index].message);
          xVolt.push(((new Date(VoltVar[index].createdAt)).getHours()).toString() + ':' 
              + ((new Date(VoltVar[index].createdAt)).getMinutes()).toString());
          //console.log(Volt)            
      }  

      // Corriente
      const AmpVar = postResult.filter(x => x.topicevento === 'ic');
        const Amp = []
        const xAmp = []
        const Afinal = AmpVar.length
        const Ainicio = 0
        for (let index = Ainicio; index < Afinal; index++) {            
            Amp.push(AmpVar[index].message);
            xAmp.push(((new Date(AmpVar[index].createdAt)).getHours()).toString() + ':' 
                + ((new Date(AmpVar[index].createdAt)).getMinutes()).toString());
            //console.log(Amp, xAmp)            
        }
      
      
        setChartData({        
          labels: xVolt,        
          datasets: [{
            label: 'Voltaje',
            data: Volt,
            borderColor: 'pink',
            backgroundColor: [
              '#c8c5e0'            
            ],
            borderColor: '#4b39db',
          },                
        ]        
        })           
          setChartDataA({        
              labels: xVolt,        
              datasets: [{
                label: 'Amperaje',
                data: Amp,
                borderColor: 'pink',
                backgroundColor: [
                  '#c8c5e0'            
                ],
                borderColor: '#4b39db',
              },                
            ]        
            })
          }

      useEffect(() => {
        const timer = setInterval( async () => {
          Chart();
          
        }, 5000);
        return () => clearInterval(timer);
      }, []);

      var options = {
        maintainAspectRatio: false,
        scales: {
          y: {
            display: true,
            min: 20,
            max: 32
          },              
        },
        legend: {
          labels: {
            fontSize: 12,
          },
        },            
          pointStyle: 'circle',
          pointRadius: 2,
          pointHoverRadius: 15
      }
      var optionsA = {
        maintainAspectRatio: false,
        scales: {
          y: {
            display: true,
            min: 0,
            max: 3
          },              
        },
        legend: {
          labels: {
            fontSize: 12,
          },
        },            
          pointStyle: 'circle',
          pointRadius: 2,
          pointHoverRadius: 15
      }  
    
  return (
    <Box
    height ={710} 
    width={1360}       
        >
          <Paper elevation={5} square={false}>
            <Box
              height ={300} 
              width={1200}  
              marginTop={5}    
              marginLeft={5}    
            >
            
              <Line
                      data={chartData}
                      height={50}
                      options={options}
                      />    
            </Box>
            <Box
              height ={300} 
              width={1200}   
              marginLeft={5}        
            >          
              <Line
                      data={chartDataA}
                      height={50}
                      options={optionsA}
                      />   
            </Box>

          </Paper>
    </Box>
    
  )
}

