import { createSlice } from '@reduxjs/toolkit';

const initialState = [
    {
        _id: "1",
        message: 10,
        topiccuenta: "cuenta1",
        topicevento: "cuenta1",
      },
     
    ];
    
export const postSlice = createSlice({
    name: 'postsSlice',
    initialState,
        reducers: {
            addPost: (state, action) => {
                let ixa = action.payload.length;
                let ixs = state.length;
                //console.log(ixa, ixs)
                for (let i = 0; i < ixa; i++) {
                    let postadd = action.payload
                    

                const foundPost = state.find((post) => post._id === postadd[i]._id);                
                //let item = JSON.parse(JSON.stringify(foundPost)) 
                //console.log(postadd[0]._id, foundPost)
                    if (foundPost === undefined ) {
                        state.push(postadd[i])
                        
                        //console.log('no encontrado', ixs);
                    } else {                    
                        //console.log('encontrado', ixs)
                    }
                }
                //console.log(state)

                for  (let i = ixs; i > 2000; i--) {
                    state.shift()
                }
                
            },

            editPost: (state, action) => {
                const { id, message, status, topic, estado, dia, hora, topiccuenta, topicevento, topicnombre } = action.payload;
                //console.log('action', action.payload);            
                const foundPost = state.find((post) => post._id === id);
                let item = JSON.parse(JSON.stringify(foundPost))   
                    foundPost.estado = estado;
                    foundPost.hora = hora;
            },  
            alarmPost: (state, action) => {
                const { id, diagnostico } = action.payload;
                const foundPost = state.find((post) => post._id === id);
                let item = JSON.parse(JSON.stringify(foundPost)) 
                //console.log(item)
                

                
            }, 
            resetPost: ( state ) => 
                    initialState,
                    
           
        }
})

export const { addPost, editPost, resetPost, alarmPost } = postSlice.actions;
export const selectPost = (state) => state.postsState;
export default postSlice.reducer