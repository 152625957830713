import React from 'react';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import HandymanOutlinedIcon from '@mui/icons-material/HandymanOutlined';
import HistoryEduOutlinedIcon from '@mui/icons-material/HistoryEduOutlined';
import { useEffect, useState } from 'react';
import { useCuentas } from '../../context/CuentaContext';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { 
  Box, 
  Button,
  Card, 
  CardHeader,   
  CardContent, 
  Grid,
  Typography, 
  IconButton,
  Tooltip 
} from "@mui/material";
import { green, lightBlue } from '@mui/material/colors';
import { useSelector } from 'react-redux';
import { selectCuenta } from '../../features/cuentas/cuentaSlide';
import { usePosts } from '../../context/PostContext';

export function EditVariable({ params, rowId, setRowId }) {

  const navigate = useNavigate();   

  
  return (
    <Box
      sx={{
        m: 1,
        position: 'relative',
      }}
    >
      <Tooltip title="Edit">
        <IconButton
          onClick={()=> navigate(`/varform/${params.id}`)}  
          //onClick={()=> console.log(params.id)}       
        >
          <EditOutlinedIcon/>
        </IconButton>
      </Tooltip>
      
      
    </Box>
  )
}


