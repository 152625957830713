import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import HistoryEduOutlinedIcon from '@mui/icons-material/HistoryEduOutlined';
import HandymanOutlinedIcon from '@mui/icons-material/HandymanOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import CreateNewFolderOutlinedIcon from '@mui/icons-material/CreateNewFolderOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';

const Menuitems = [
  {
    title: "Home",
    icon: HomeOutlinedIcon,
    href: "/home",
  },
  {
    title: "Cuentas",
    icon: AccountBalanceWalletOutlinedIcon,
    href: "/cuenta",
  },
  {
    title: "Nuevo",
    icon: CreateNewFolderOutlinedIcon,
    href: "/cuentaform",
  }, 
  {
    title: "Ubicacion",
    icon: MapOutlinedIcon,
    href: "/map",
  },
  {
    title: "Historico",
    icon: HistoryEduOutlinedIcon,
    href: "/his",
  },
  {
    title: "Trabajos",
    icon: HandymanOutlinedIcon,
    href: "/job",
  },
  {
    title: "Usuarios",
    icon: PersonOutlineOutlinedIcon,
    href: "/usu",
  },
  
  {
    title: "Configuracion",
    icon: SettingsOutlinedIcon,
    href: "/set",
  },
  {
    title: "Visor",
    icon: ArticleOutlinedIcon,
    href: "/vis",
  },
  
];

export default Menuitems;
