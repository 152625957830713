import React from 'react';
import HistoryEduOutlinedIcon from '@mui/icons-material/HistoryEduOutlined';
import { useState } from 'react';
import { 
  Box, 
  Button,   
  Grid,
  Paper,  
  IconButton,
  Typography,
  Tooltip 
} from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import { useSelector } from 'react-redux';
import { selectCuenta } from '../../../features/cuentas/cuentaSlide';
import CircularProgress from '@mui/material/CircularProgress';
import { EventGraph } from './EventGraph';
import { selectPost } from '../../../features/post/postSlide';


export function MonitorEvent({ params, rowId, setRowId }) {

  
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => { setOpen(true);  };
  const handleClose = () => { setOpen(false); };
  const cuentas = useSelector(selectCuenta); 
  const posts = useSelector(selectPost) 
  
  const postResult = posts.filter(x => x._id === params.id)  
  const cuentaResult = cuentas.filter(x => x.nroorden === postResult[0].dia)
  //console.log('monitorevent', params.id, postResult, cuentaResult)
  
  return (
    <Box>
      <Tooltip title="Evento">
        <IconButton          
            onClick={
              handleClickOpen}          
            //onClick={()=> navigate(`/monfor/${params.id}`)}  
            //onClick={()=> console.log(params.id)}   
          >
            <HistoryEduOutlinedIcon color='primary'/>
          </IconButton>
      </Tooltip>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="110%"
      >
        <Box 
            width={1150}
            padding={1}
          >
               { postResult.length > 0 ?   < EventGraph params={{params}} /> : 
                  <Grid
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                >    
                    <Grid marginY={5}></Grid>
                    <Grid marginY={5}>Cargando... o SIN EVENTOS</Grid>
                    <Grid marginY={5}><CircularProgress /></Grid>
                  </Grid>}                
              
          </Box>
        <DialogActions>          
          <Button onClick={handleClose} autoFocus>
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>




    </Box>
  )
}


