import React, { useEffect, useState } from 'react';
import {
  Box,
  Grid,   
} from "@mui/material";
import {     
    DataGrid,     
  } from '@mui/x-data-grid';
import { MonitorEdit } from './MonitorEdit';
import { usePosts } from '../../context/PostContext';
import CircularProgress from '@mui/material/CircularProgress';
import { MonitorVisor } from './MonitorVisor';
import { useSelector } from 'react-redux';
import { selectVariable } from '../../features/variables/variableSlide';
import { useVariables } from "../../context/VariablesContext";
import { selectPost } from '../../features/post/postSlide';

export function MonitorPage() {  
  
  const [rowId, setRowId] = useState(null);
  const posts = useSelector(selectPost)
  const variables = useSelector(selectVariable)  

  /*  
  useEffect(() => {      
    const alarm = variables.filter(x => x.definicion ===  'alarmaMinimo' )
    console.log(alarm)
    const valor = alarm[0].valor
    setVbateria(valor);
  }, []); 
  console.log(vbateria)
  */
 
  const { vbateria, variable, getVariable } = useVariables([]);
  const Vbat = vbateria;
  //console.log('visor', vbateria);
  
  const postsA1 = posts.filter(post => (post.topicevento === "vbat"));
  const postsA2 = postsA1.filter(post => (post.message < Vbat));
  const postsA3 = postsA2.filter(post => (post.estado !== "fin"));
  //console.log(postsA3)   
  
  return (
    <div>
        { postsA3.length > 0 ?  < MonitorVisor/>: 
          <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >    
            <Grid marginY={5}></Grid>
            <Grid marginY={5}>SIN ALARMAS</Grid>
            <Grid marginY={5}><CircularProgress /></Grid>
          </Grid>}     
    </div>
  )
}


