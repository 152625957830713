import { createSlice } from '@reduxjs/toolkit';

const initialState = [
   {
    "dispositivo": "nunca",
    "observaciones": "",
    "codigo": "",
   }
];

export const jobSlice = createSlice({
    name: 'jobsSlice',
    initialState,
    reducers: {
        addJob: (state, action) => {
            let ixs = state.length;
                let ixa = action.payload.length;
                var rec = ixa-ixs;
                if (rec > 0) {
                    for (let i = ixs; i < ixa; i++) {
                        let jobadd = action.payload
                        //console.log('estado addTask', postadd[i] );
                        //state.every(postadd[i]);
                        //state.push(postadd[i]);                         
                        state.push(jobadd[i]);
                    }   
                }
            },
            resetJob: ( state ) => 
                    initialState,
        }
    })
        
export const { addJob, resetJob } = jobSlice.actions;
export const selectJob = (state) => state.jobsSate;
export default jobSlice.reducer





