import { createContext, useContext, useEffect, useState } from "react";
import { getHistoryRequest, getHistorysRequest } from "../api/history";

const HistoryContext = createContext();

export const useHistorys = () => {
    const context = useContext(HistoryContext);
    if(!context) {
      throw new Error("usePost must be used within a HistoryProvider")
    }
    return context;
  }

  export function HistoryProvider({ children }) {
    const [historys, setHistorys] = useState([]);

    
    useEffect(() => {
      (async () => {
        const res = await getHistorysRequest();
        setHistorys(res.data);
      })();
    }, []);
  
    const getHistorys = async () => {
      try {
        const res = await getHistorysRequest()
        //console.log('contx gets  cuenta:', res)
        setHistorys(res.data);
      } catch (error) {
        console.log('error getPots:', error);
      }
    }    
  
    const getHistory = async (id) => {
      try {
        const res = await getHistoryRequest(id);
        return res.data
      } catch (error) {
        console.log(error)
      }
    }  
    
    return (
      <HistoryContext.Provider value={{ 
        getHistory,
        historys,
        getHistorys
      }}>
        {children}
      </HistoryContext.Provider>
    )
  }
  