import React, {useState, useEffect } from 'react';
import { 
  Box, 
  Button,
  Card, 
  CardHeader,   
  CardContent, 
  Typography, FormControlLabel, Radio,
} from "@mui/material";
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectCuenta } from '../../../../features/cuentas/cuentaSlide';
import { selectJob } from '../../../../features/jobs/jobSlide';
import { useParams } from "react-router-dom";
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import moment from 'moment';
import { usePosts } from '../../../../context/PostContext';


export function BoxJob({results}) {
 
  const cuentas = useSelector(selectCuenta)
  const jobs = useSelector(selectJob)
  const navigate = useNavigate();   


  const cuentaResult = cuentas.filter(x => x._id === results)
  const jobResult = jobs.filter(job => job.cuentaID === cuentaResult[0]._id)
    
    //console.log('card', postResult[0]._id, results, jobResult);
    const columns = [    
      {
        field: "createdAt",
        headerName: "Fecha",
        width:110,
        valueFormatter: params => new Date(params?.value).toLocaleString('en-GB'),       
      },
      {
        field: "dispositivo",
        headerName: "Dispositivo",
        width:120,     
      },
      {
        field: "observaciones",
        headerName: "Observaciones",
        width:400,     
      },
      {
        field: "codigo",
        headerName: "Codigo",
        flex: 1,      
      },
    ]
  
    const [sortModel, setSortModel] = useState([
      {
        field: 'createdAt',
        sort: 'desc',
      },
    ]);
  

  return (
    <Box 
      width={715} 
      height={412}    
      
    >
      <Box
        padding={1}
      >
        <Button 
                  variant="outlined"
                  color="secondary"                      
                  startIcon={<AddCircleOutlineOutlinedIcon />} 
                  size="small"
                  sx={{ padding: '2',  }}
                  onClick={()=> navigate(`/cue/${results}`)}
                  
                  
                >Nuevo Trabajo</Button>    
      </Box>
      
      <Box
        
        height={350}
        width={700}
        padding={1}

        > 
        <DataGrid           
          rows={jobResult} 
          columns={columns} 
          slots={{ toolbar: GridToolbar }}
          getRowId={(row) => row._id}
          rowHeight={19} 
          sortModel={sortModel}                        
          onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
        />
      </Box>
    </Box>
  )
}



