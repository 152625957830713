import React from 'react';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import HandymanOutlinedIcon from '@mui/icons-material/HandymanOutlined';
import HistoryEduOutlinedIcon from '@mui/icons-material/HistoryEduOutlined';
import { useEffect, useState } from 'react';
import { useCuentas } from '../../context/CuentaContext';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { 
  Box, 
  Button,
  Card, 
  CardHeader,   
  CardContent, 
  Grid,
  Paper,
  Typography, 
  IconButton,
  Tooltip 
} from "@mui/material";
import { green, lightBlue } from '@mui/material/colors';
import { useSelector } from 'react-redux';
import { selectCuenta } from '../../features/cuentas/cuentaSlide';
import { selectPost } from '../../features/post/postSlide';
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { MonitorDialog }from './MonitorDialog';  
import { MonitorForm } from './MonitorForm';
import { usePosts } from '../../context/PostContext';
import { red } from '@mui/material/colors';


export function MonitorEdit({ params, rowId, setRowId }) {

  const navigate = useNavigate(); 
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => { setOpen(true);  };
  const handleClose = () => { setOpen(false); };
  const cuentas = useSelector(selectCuenta); 
  const posts = useSelector(selectPost) 
  
  //console.log('params',  params.id)

  const results = posts.filter(x => x._id === params.id)
  const cuentaR1 = cuentas.filter(x => (x.nroorden === results[0].topiccuenta)) 
  
  //console.log('alarm', params.id)
  
  return (
    <Box
      sx={{
        m: 1,
        position: 'relative',
      }}
    >
      <Tooltip title="Editar">
        <IconButton          
            onClick={handleClickOpen}          
            //onClick={()=> navigate(`/monfor/${params.id}`)}  
            //onClick={()=> console.log(params.id)}   
          >
            <NotificationsActiveOutlinedIcon color='secondary'/>
          </IconButton>
      </Tooltip>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Typography variant="h6" color="secondary" >Estado - {results[0].estado}</Typography>
        </DialogTitle>
        <Box
          paddingX={2}
        >
          <Paper elevation={24} square={false}>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">                 
                 <MonitorDialog params={params.id}/> 
              </DialogContentText>
            </DialogContent>
          </Paper>
        </Box>
        <Box
          padding={2}
        >
          <Paper elevation={24} square={false}>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">              
                <MonitorForm params={params.id}/>
              </DialogContentText>
            </DialogContent>
          </Paper>
        </Box>
        
        <DialogActions>          
          <Button onClick={handleClose} autoFocus>
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}


