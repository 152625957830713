import React from 'react';
import HistoryEduOutlinedIcon from '@mui/icons-material/HistoryEduOutlined';
import { useState } from 'react';
import { 
  Box, 
  Button,   
  Grid,
  Paper,  
  IconButton,
  Typography,
  Tooltip 
} from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import { useSelector } from 'react-redux';
import { selectCuenta } from '../../../features/cuentas/cuentaSlide';
import { useAuth } from "../../../context/AuthContext";
import { selectPost } from '../../../features/post/postSlide';
import { usePosts } from '../../../context/PostContext';
import { BoxCuenta } from './Box/BoxCuenta';
import { BoxJob } from './Box/BoxJob';
import { BoxVolt} from './Box/BoxVolt';
import { BoxSeguridades } from './Box/BoxSeguridades';
import { BoxSegurOper } from './Box/BoxSegurOper';
import { useParams } from "react-router-dom";



export function EventGraphMap() {

  const params = useParams();  
  const {isAuthenticated, logout, user } = useAuth();  

  
  const cuentas = useSelector(selectCuenta); 
  const posts = useSelector(selectPost) 
  const cuentaResult = cuentas.filter(x => x._id === params.id)
  const postResult = posts.filter(x => x.topiccuenta === cuentaResult[0].nroorden)
  
  
  // Box Seguridades
  const voltR = (postResult.filter(x => (x.topicevento === "vbat"  ))).length
  const postResultSeg = (postResult.filter(x =>  x.topicevento === "seguridades" )).length
  const onlineData1 = (postResult.filter(x =>  x.topicevento === "online" )).length
  const marcha = (postResult.filter(x =>  x.topicevento === "c_marcha" )).length
  const tiempo = (postResult.filter(x =>  x.topicevento === "s_marcha" )).length
  const Valida = voltR * postResultSeg * onlineData1 * marcha * tiempo
  
  //console.log(Valida)

  const cuentaid = cuentaResult[0]._id
  //console.log(cuentaid)

  return (
    <Box 
            width={1162}
            padding={1} 
            >
        <Paper elevation={0} square={false}>
          <Box
          width={1162}
            >          
              <Grid container  >
                <Box margin={1}>
                <Paper elevation={10} square={false}>
                      < BoxCuenta results={cuentaid} />
                    </Paper>
                </Box>  

                <Box margin={1}>                
                    <Paper elevation={10} square={false} >
                      {( Valida !== 0 ) ?  <BoxVolt results={cuentaid} /> : <Box
                            height ={205} 
                            width={715}          
                          ></Box>  }                    
                    </Paper>
                </Box>

                <Box margin={1}>
                    <Paper elevation={10} square={false}>  
                      {( Valida !== 0 ) & ( user.role === 'admi' )? <BoxSeguridades results={cuentaid}/> :<div></div>  }  
                      {( Valida !== 0 ) & ( user.role !== 'admi' )? <BoxSeguridades results={cuentaid}/> :<div></div>  }           
                    </Paper>
                </Box>

                <Box  margin={1} >
                    <Paper elevation={10} square={false}>
                          <Box>
                            <BoxJob results={cuentaid}/>
                          </Box>
                      </Paper>
                  </Box>
              </Grid>
          </Box>
        </Paper>      
    </Box>
  )
}


