import React, {useState, useEffect } from 'react';
import { 
  Box, 
  Button,
  Card, 
  CardHeader,   
  CardContent, 
  Grid,
  Paper,
  Typography, FormControlLabel, Radio,
} from "@mui/material";
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectCuenta } from '../../../features/cuentas/cuentaSlide';
import { selectJob } from '../../../features/jobs/jobSlide';
import { useParams } from "react-router-dom";
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import moment from 'moment';
import SignalWifiStatusbarConnectedNoInternet4OutlinedIcon from '@mui/icons-material/SignalWifiStatusbarConnectedNoInternet4Outlined';
import SignalWifiConnectedNoInternet4OutlinedIcon from '@mui/icons-material/SignalWifiConnectedNoInternet4Outlined';
import ModeStandbyOutlinedIcon from '@mui/icons-material/ModeStandbyOutlined';
import { green, red } from '@mui/material/colors';

export function BoxSeguridad({ result}) {
    
    const Tmarcha = result.c_seg
    const TmarchaMinutos =  Math.floor(Tmarcha / 60)
    const Tmarchasegundos = Tmarcha % 60;
    const TmarchaHoras = Math.floor(TmarchaMinutos / 60)
    const TmarchaMinutos1 = TmarchaMinutos % 60;

    if (result.cr === undefined ) {
        var cr1 = 0
        var cr2 = 0
        var cr3 = 0
        var cr4 = 0
    } else {
        const cr = (result.cr).split(',')
        var cr1 = cr[0]
        var cr2 = cr[1]
        var cr3 = cr[2]
        var cr4 = cr[3]
    }   

    //console.log(cr1, cr2, cr3, cr4)

  return (
    <Box>
        <Grid
              container
              direction="row"
              justifyContent="space-around"
              alignItems="center"
              
            >
                 <Box
                  
                  >
                    <Grid
                      container
                      direction="column"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      >
                        <Grid paddingLeft={1} >
                         <Box   
                            >
                              
                                  <Grid
                                      container
                                      direction="row"
                                      justifyContent="center"
                                      alignItems="center"                                       
                                      >
                                          <Grid marginLeft={1}><SignalWifiStatusbarConnectedNoInternet4OutlinedIcon sx={{ color: green[200], fontSize: 20 }}/></Grid>
                                          <Grid marginLeft={2}><Typography color='secondary' variant='body2'>Ult Evento</Typography></Grid>
                                          <Grid marginLeft={2}><Typography color='secondary' variant='body2'>{result.date0} - {result.date1}</Typography></Grid>  
                                  </Grid>                              
                            </Box>
                        </Grid>
                      {/*   primer bloque  */}  
                      {/*   segundo bloque  */}
                        <Grid paddingLeft={1} >
                         <Box>
                              
                                  <Grid
                                      container
                                      direction="row"
                                      justifyContent="center"
                                      alignItems="center"                                       
                                      >                                            
                                        <Grid item xs={1} paddingLeft={2}>
                                        {(result.a1 > 0)? <ModeStandbyOutlinedIcon sx={{ color: red[200], fontSize:20 }}/>:
                                        <ModeStandbyOutlinedIcon sx={{ color: green[200], fontSize: 20 }}/> }
                                        </Grid>
                                        <Grid item xs={5} paddingLeft={2}><Typography variant='body2'>Final Subida</Typography> </Grid> 
                                          <Grid item xs={1} paddingLeft={2}>
                                              {(result.a2 > 0)? <ModeStandbyOutlinedIcon sx={{ color: red[200], fontSize:20 }}/>:
                                              <ModeStandbyOutlinedIcon sx={{ color: green[200], fontSize: 20 }}/> }
                                          </Grid>
                                          <Grid item xs={5} paddingLeft={2}><Typography variant='body2'>Final Bajada</Typography> </Grid>  

                                          <Grid item xs={1} paddingLeft={2}>
                                              {(result.a3 > 0)? <ModeStandbyOutlinedIcon sx={{ color: red[200], fontSize:20 }}/>:
                                              <ModeStandbyOutlinedIcon sx={{ color: green[200], fontSize: 20 }}/> }
                                          </Grid>
                                          <Grid item xs={5} paddingLeft={2}><Typography variant='body2'>Butaca</Typography> </Grid> 

                                          <Grid item xs={1} paddingLeft={2}>
                                              {(result.a4 > 0)? <ModeStandbyOutlinedIcon sx={{ color: red[200], fontSize:20 }}/>:
                                              <ModeStandbyOutlinedIcon sx={{ color: green[200], fontSize: 20 }}/> }
                                          </Grid>
                                          <Grid item xs={5} paddingLeft={2}><Typography variant='body2'>Final Pasada</Typography> </Grid> 
                                          <Grid item xs={1} paddingLeft={2}>
                                              {(result.a5 > 0)? <ModeStandbyOutlinedIcon sx={{ color: red[200], fontSize:20 }}/>:
                                              <ModeStandbyOutlinedIcon sx={{ color: green[200], fontSize: 20 }}/> }
                                          </Grid>
                                          <Grid item xs={5} paddingLeft={2}><Typography variant='body2'>Apoya Pies</Typography> </Grid> 
                                          <Grid item xs={1} paddingLeft={2}>
                                              {(result.a6 > 0)? <ModeStandbyOutlinedIcon sx={{ color: red[200], fontSize:20 }}/>:
                                              <ModeStandbyOutlinedIcon sx={{ color: green[200], fontSize: 20 }}/> }
                                          </Grid>
                                          <Grid item xs={5} paddingLeft={2}><Typography variant='body2'>Parada Suave</Typography> </Grid> 
                                        
                                        
                                  </Grid>
                                  <br/>
                                                                  
                            </Box>
                        </Grid>
                        {/*   segundo bloque  */}
                        <Grid
                          container
                          direction="row"
                          sx={{
                            justifyContent: "flex-start",
                            alignItems: "center",
                          }}
                        >
                          <Box>
                            <Box marginLeft={2}><Typography variant='body2'>Estado: {result.statusl}</Typography></Box>
                            <Box marginLeft={2}><Typography variant='body2'>Cargador {result.charg}</Typography></Box>
                            <Box marginLeft={2}><Typography variant='body2'>Marcha: {result.c_marcha}</Typography></Box>
                            <Box marginLeft={2}><Typography variant='body2'>Tiempo: {TmarchaHoras}:{TmarchaMinutos1}:{Tmarchasegundos} Hs.</Typography></Box> 
                            <Box marginLeft={2}><Typography variant='body2'>Error {result.error}</Typography></Box>
                          </Box>
                          <Box
                            marginLeft={7}
                          >
                            <Box marginLeft={2}><Typography variant='body2'>Cr1: {cr1}</Typography></Box>  
                            <Box marginLeft={2}><Typography variant='body2'>Cr2: {cr2}</Typography></Box> 
                            <Box marginLeft={2}><Typography variant='body2'>Cr3: {cr3}</Typography></Box>                                   
                            <Box marginLeft={2}><Typography variant='body2'>Cr4: {cr4}</Typography></Box> 
                          </Box>



                        </Grid>






                    </Grid>                  
                </Box>
                
                
                
                
                  
        </Grid>
    </Box>
  )
}


