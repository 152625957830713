import React from 'react';
import { Formik, Form, Field, ErrorMessage } from "formik";
import { getCuentaRequest, createCuentaRequest, updateCuentaRequest } from "../../api/cuenta";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Button, TextField, useColorScheme, useTheme, Typography, MenuItem } from "@mui/material";
import { useDispatch} from "react-redux";
import { editCuenta } from "../../features/cuentas/cuentaSlide";
import { useAuth } from "../../context/AuthContext";
import { getPostRequest, updatePostRequest } from "../../api/post";
import { editPost } from "../../features/post/postSlide";

const estadoEven = [
  {
    value: "alarma",
    label: "Alarma",
  },  
  {
    value: "esp",
    label: "Esperado",
  },  
  {
    value: "fin",
    label: "Cerrar"
  },   
]

export function MonitorForm(params) {

  const navigate = useNavigate(); 
  const [ post, setPost] = useState({
    message: "", 
    status:"",
    topic:"",
    estado:"",
    dia:"",
    hora:"",
    topiccuenta: "",
    topicevent: "",
    topicnombre: "",
  });  
  
  const dispatch = useDispatch();  
  const { user } = useAuth();

  const paramsID = params.params

  useEffect(() => {
    (async () => {
      if (paramsID) {
        const res = await getPostRequest(paramsID);
        //console.log('Post', res.data)
        const post = res.data


        setPost({
          message: post.message, 
          status: post.status,
          topic: post.topic,
          estado: post.estado,
          dia: post.dia,
          hora: post.hora,
          topiccuenta: post.topiccuenta,
          topicevent: post.topicevent,
          topicnombre: post.topicnombre,      
        });
      }
    })();
  }, []);
  //console.log(user)
    
  return (
    <Box 
      marginTop={5} 
      maxWidth={800}
    >
      
      <Formik
        initialValues={post}
        enableReinitialize
        onSubmit={async (values, actions) => {  
          if (paramsID) {
            
            updatePostRequest(params.params, values)
            setPost(values);
            //console.log('update', paramsID, values);
            dispatch(editPost({ ...values, id: params.params }));

          } else {
            console.log(values);
            //createCuentaRequest(values)
          }
            actions.resetForm();
            actions.setSubmitting(false);
            navigate('/mon');
          }}
      >   
        {({
            setFieldValue, 
            isSubmitting, 
            handleSubmit, 
            handleChange, 
            handleBlur,
            values 
        }) => (
          <Form onSubmit={handleSubmit}>
            <Box
                  m="40px"
                  display="grid"
                  gap="30px"
                >
                  <TextField
                        fullWidth
                        variant="filled"
                        type="text"
                        label="Observaciones"                       
                        onChange={handleChange}
                        value={values.hora}
                        name="hora"
                        sx={{ gridColumn: "span 2" }} 
                        autoFocus={true}                      
                      />
                  
                  <TextField
                        id="outlined-select-currency"
                        select                        
                        label="Estado"                        
                        onChange={handleChange}
                        value={values.estado}
                        name="estado"
                        sx={{ gridColumn: "span 1" }}                                              
                      >{estadoEven.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}  
                  </TextField>
                      
                  
                </Box>
                <Button 
                  type='submit'
                  variant="outlined"
                  sx={{ mx: 10, my: 2 }}
                  >Cambiar estado</Button>
          </Form>
        )
        }
      </Formik>
    </Box>
  )
}


