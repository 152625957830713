import React from 'react';
import { Formik, Form, Field, ErrorMessage } from "formik";
import { getVariableRequest, updateVariableRequest, createVariableRequest } from "../../api/variable";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Button, TextField, useColorScheme, useTheme, Typography, MenuItem } from "@mui/material";
import { editVariable } from '../../features/variables/variableSlide';
import { useAuth } from "../../context/AuthContext";
import { useDispatch} from "react-redux";
import { useVariables } from '../../context/VariablesContext';


export function FormVariable() {

    const { variable } = useVariables();
    const navigate = useNavigate(); 
    const dispatch = useDispatch();
    const [ vari, setVari ] = useState({
        definicion: "",
        valor: "",
        texto: "",
    });
    const params = useParams();     
    const { user } = useAuth();

    //console.log('params', params.id)

    useEffect(() => {
        (async () => {
          if (params.id) {
            const res = await getVariableRequest(params.id);            
            const vari = res.data
            //console.log('vari', vari)
            setVari({
              definicion: vari.definicion,      
              valor: vari.valor,    
              texto: vari.texto,
              user: user.id,          
            });
          }
        })();
      }, []);
      console.log(vari)



  return (
    <Box 
    marginTop={5} 
    maxWidth={800}
    >
        <Formik
            initialValues={vari}
            enableReinitialize
            onSubmit={async (values, actions) => {  
            if (params.id) {
                
                updateVariableRequest(params.id, values)
                setVari(values);
                //console.log('update', params.id, values);
                dispatch(editVariable({ ...values, id: params.id }));

            } else {
                //console.log('create',values);
                createVariableRequest(values)
            }
                actions.resetForm();
                actions.setSubmitting(false);
                navigate('/set');
            }}
            >


{({
            setFieldValue, 
            isSubmitting, 
            handleSubmit, 
            handleChange, 
            handleBlur,
            values 
        }) => (
          <Form onSubmit={handleSubmit}>
            <Box
                  m="40px"
                  display="grid"
                  gap="30px"
                >
                  <TextField
                        fullWidth
                        variant="filled"
                        type="text"
                        label="Definicion"                       
                        onChange={handleChange}
                        value={values.definicion}
                        name="definicion"
                        sx={{ gridColumn: "span 1" }} 
                        autoFocus={true}                      
                      />
                  <TextField
                        fullWidth
                        variant="filled"
                        type="text"
                        label="Variable Alarma"                       
                        onChange={handleChange}
                        value={values.valor}
                        name="valor"
                        sx={{ gridColumn: "span 1" }}                                              
                      />
                  <TextField
                        fullWidth
                        variant="filled"
                        type="text"
                        label="Descripcion"                       
                        onChange={handleChange}
                        value={values.texto}
                        name="texto"
                        sx={{ gridColumn: "span 1" }}                                              
                      />
                  
                  
                      
                  
                  
                </Box>
                <Button 
                  type='submit'
                  variant="outlined"
                  sx={{ mx: 60, my: 2 }}
                  >Guardar</Button>
          </Form>
        )
        }









                
                
                
                
                
            </Formik> 
    </Box>
  )
}


