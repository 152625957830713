import { selectCuenta } from '../../features/cuentas/cuentaSlide';
import { useSelector } from 'react-redux';
import { selectPost } from '../../features/post/postSlide';
import { selectJob } from '../../features/jobs/jobSlide';
import React, { useEffect, useState } from 'react';
import { useVariables } from "../../context/VariablesContext";
import { MonitorEdit } from './MonitorEdit';
import { MonitorEvent } from './Event/MonitorEvent';
import { DataGrid } from '@mui/x-data-grid';

import {
  Box,
  Grid,   
} from "@mui/material";


export function MonitorVisor() {

    const posts = useSelector(selectPost)
    const jobs = useSelector(selectJob)
    const cuenta = useSelector(selectCuenta)
    const [rowId, setRowId] = useState(null);
    const [sortModel, setSortModel] = useState([
        {
        field: 'estado',
        sort: 'asc',
        },
    ]);     

    const resumePost = posts.filter(x => ( x._id !== "1" ))
    const reslenght = resumePost.length
    const { vbateria, variable, getVariable } = useVariables([]);
    const Vbat = vbateria;
    const postsA1 = posts.filter(post => (post.topicevento === "vbat"));
    const postsA2 = postsA1.filter(post => (post.message < Vbat));
    const postsA3 = postsA2.filter(post => (post.estado !== "fin"));
    // console.log('A3', postsA3) 

     const columns = [  
      {
        field: "estado",
        headerName: "Estado",
        width:100,       
      },
      {
        field: "diagnostico",
        headerName: "Diagnostico",
        width:120,       
      },
      {
        field: "createdAt",
        headerName: "Fecha",      
        width:160,      
        valueFormatter: params => new Date(params?.value).toLocaleString('en-GB')
      },    
      {
        field: "topiccuenta",
        headerName: "Cuenta",
        width: 70,  
        align:"center",   
      },    
      {
        field: "message",
        headerName: "Valor",
        width:80,       
      },
      
      {
        field: "hora",
        headerName: "Observaciones",
        width:300,   
      },  
      {
        field: "Disp",
        headerName: "Disp",
        type:'action',
        align:"center",
        renderCell: (params)=><MonitorEdit 
          {...{ params, rowId, setRowId }}
          />,
          width:40,     
      },
      {
        field: "Even",
        headerName: "Even",
        type:'action',
        align:"center",
        renderCell: (params)=><MonitorEvent
          {...{ params, rowId, setRowId }}
          />,
          width:40,     
      },
    ]
  

  return (
    <Grid
      container
      direction="row"
      justifyContent="left"
      alignItems="center"
      marginLeft={15}
    >      
      <Box
              sx={{
                marginTop: 5,
                height: 550,
                width: 980,                
                '& .hot': {
                  backgroundColor: '#F11F1F',
                  color: '#1a3e72',
                },
              }}              
          >
            <DataGrid
                  rows={ postsA3 } 
                  columns={columns} 
                  getCellClassName={(params) => {
                    return params.value === "alarma" ? 'hot' : 'cold';
                  }}
                  
                  getRowId={(row) => row._id} 
                  rowHeight={23} 
                  sortModel={sortModel}
                  onSortModelChange={(newSortModel) => setSortModel(newSortModel)}    
                  getRowSpacing={(params) => ({
                    top: params.isFirstVisible ? 0 : 2,
                    bottom: params.isLastVisible ? 0 : 2,
                  })}
                  onCellClick ={(params) => setRowId(params.id)}   
                />







          </Box>
    </Grid>       
    
  )
}


