import axios from './axios';

export const getHistorysRequest = () => axios.get('/historia', {  maxContentLength: 10 })

export const getHistoryRequest = (id) => axios.get(`/historia/${id}`);

export const getHistoryEvent = (id) => axios.get(`/historys/${id}`, {
    params: {
        pagina: 1, 
        limite: 10, 
    }    
});







