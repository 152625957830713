import axios from './axios';

export const getCuentasRequest = () => axios.get('/cuentas');

export const getCuentaRequest = (id) => axios.get(`/cuentas/${id}`);

export const getCuentaRequestUser = () => axios.get('/cuentasuser');

export const createCuentaRequest = (cuenta) => axios.post('/cuentas', cuenta);

export const updateCuentaRequest = (id, cuenta) => axios.put(`/cuentas/${id}`, cuenta);

export const deleteCuentaRequest = (id) => axios.delete(`/cuentas/${id}`);