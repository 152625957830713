import axios from './axios';

export const getVistasRequest = () => axios.get('/Vista');

export const getVistaRequest = (id) => axios.get(`/vista/${id}`);

//export const getCuentaRequestUser = () => axios.get('/cuentasuser');

//export const createCuentaRequest = (cuenta) => axios.post('/cuentas', cuenta);

export const updateVistaRequest = (id, vista) => axios.put(`/vista/${id}`, vista);

//export const deleteCuentaRequest = (id) => axios.delete(`/cuentas/${id}`);