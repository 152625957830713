import React from 'react';
import HistoryEduOutlinedIcon from '@mui/icons-material/HistoryEduOutlined';
import { useState } from 'react';
import { 
  Box, 
  Button,   
  Grid,
  Paper,  
  IconButton,
  Typography,
  Tooltip 
} from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import { useSelector } from 'react-redux';
import { selectCuenta } from '../../../features/cuentas/cuentaSlide';
import { usePosts } from '../../../context/PostContext';
import { BoxCuenta } from '../../Cuenta/Event/Box/BoxCuenta';
import { BoxJob } from '../../Cuenta/Event/Box/BoxJob';
import { BoxSeguridades } from '../../Cuenta/Event/Box/BoxSeguridades';
import { BoxVolt } from '../../Cuenta/Event/Box/BoxVolt';

export function EventGraph({params}) {  
  
  const cuentas = useSelector(selectCuenta); 
  const { posts } = usePosts(); 
  const postResult = posts.filter(x => x._id === params.params.id)  
  const cuentaResult = cuentas.filter(x => x.nroorden === postResult[0].dia)
  //console.log(params.params.id, postResult, cuentaResult )
  
  const cuentaid = cuentaResult[0]._id
  //console.log(cuentaid)

  return (
    <Box
      width={1150}    
        >
          <Typography variant="h5" color='primary' gutterBottom paddingLeft={2}>Parametros de equipo</Typography>
          <Grid container columns={{ xs: 4, sm: 8, md: 12 }} marginTop={2}>
            <Box height ={205} width = {412} >
                <Paper elevation={24} square={false}>
                      <BoxCuenta results={cuentaid} />
                  </Paper>
            </Box>  

            <Box height ={203} width={718} marginLeft={1} >
                <Paper elevation={24} square={false}>
                  < BoxVolt results={cuentaid} />
                  </Paper>
            </Box>

            <Box width ={412} height={400}>
                <Paper elevation={24} square={false}>
                  <BoxSeguridades results={cuentaid}/>
                </Paper>
            </Box>

            <Box maxWidth={750} height={203} marginLeft={1} marginTop={1}>
                <Paper elevation={24} square={false}>
                      <Box>
                          <BoxJob results={cuentaid}/>
                      </Box>
                  </Paper>
              </Box>
          </Grid>
    </Box>
  )
}


