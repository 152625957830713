import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  cheker: false,  
};

  export const checkSlice = createSlice({
    name: 'checksSlice',
    initialState,
    reducers: {
        checkMode: (state, action) => {
            state.cheker = action.payload;
            //console.log(state.cheker)
        }
    }
  });

export const { checkMode } = checkSlice.actions;
export const selectCheck = (state) => state.checksSlice;
export default checkSlice.reducer
  