import React, {useEffect, useState} from 'react';
import {
    Box, Paper
  } from "@mui/material";
  import { useNavigate, Link, useParams } from 'react-router-dom';
  import { 
    GridRowModes,
    DataGrid, 
    GridToolbar,
    GridToolbarContainer, 
    GridActionsCellItem,
    GridRowEditStopReasons,
  } from '@mui/x-data-grid';
import { useSelector } from 'react-redux';
import { selectCuenta } from '../../features/cuentas/cuentaSlide';
import { selectJob } from '../../features/jobs/jobSlide';
import { TablaEdit } from "./TablaEdit";
import { MonitorDash } from '../NewDash/MonitorDash';
import { HomeCuenta } from './Table/HomeCuenta'

export function CuentaPage() {

    const cuentas = useSelector(selectCuenta)
    const jobs = useSelector(selectJob)
    const navigate = useNavigate(); 
    const [rowId, setRowId] = useState(null);
    const params = useParams();
    //console.log(cuentas)

    
    
      const cuentasVisor = cuentas.filter(x => (x.nombre !== "inicial"  && x.habilitado === 'habi')) 
      console.log(cuentasVisor)

      
      

  return (
    <Box>
      <HomeCuenta cuentasVisor={cuentasVisor} />
    </Box>
  )
}