import axios from './axios';

export const getJobsRequest = () => axios.get('/jobs');

export const createJobRequest = (job) => axios.post('/jobs', job);

export const getJobRequest = (id) => axios.get(`/jobs/${id}`);



