import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';

export function ProtectedRoute() {
  
  const { loading, isAuthenticated } = useAuth()
  //console.log('load  auth:',loading, isAuthenticated);

  if(loading) return <h1>...cargando</h1>
  if(!loading && !isAuthenticated) return <Navigate to="/" replace />

  return <Outlet/>;
}


