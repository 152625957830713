import React, { useEffect, useState } from 'react';
import { useLocation } from "react-router";
import { Link, NavLink } from "react-router-dom";
import {
  Box,
  Drawer,
  useMediaQuery,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography
} from "@mui/material";
import { SidebarWidth } from "../../assets/global/Theme-variable";
//import LogoIcon from "../../image/logo.jpg";
import Menuitems from "./data";
import LogoIcon from "../../image/logo.jpg";
import Image from 'mui-image'

export function Sidebar(props) {
  const [open, setOpen] = useState(true);
  const { pathname } = useLocation();
  const pathDirect = pathname;
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"));

  const handleClick = (index) => {
    if (open === index) {
      setOpen((prevopen) => !prevopen);
    } else {
      setOpen(index);
    }
  };
  const SidebarContent = (
    <Box sx={{ p: 3, height: "calc(100vh - 40px)" }}>
      <Link to="/">
        <Box sx={{ display: "flex", alignItems: "Center" }}          
        >
          <img alt="Logo" src={LogoIcon} width={140}/>
        </Box>
      </Link>
      <Box>
        <List
            sx={{
              mt: 2,
            }}
          >
             {Menuitems.map((item, index) => {
                return (
                  <List component="li" disablePadding key={item.title}>
                    <ListItem
                        onClick={() => handleClick(index)}
                        button
                        component={NavLink}
                        to={item.href}
                        selected={pathDirect === item.href}
                        sx={{
                          
                          mb: 1,
                          ...(pathDirect === item.href && {
                            color: "white",
                            backgroundColor: (theme) =>
                              `${theme.palette.primary.main}!important`,
                          }),
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            ...(pathDirect === item.href && { color: "white" }),
                          }}
                        >
                          <item.icon width="18" height="18" />
                        </ListItemIcon>
                        <Typography color="textSecondary" sx={{
                          fontSize: "12px", fontWeight: "600" }}>{item.title}
                        </Typography>                        
                      </ListItem>
                  </List>
                )
             })}
        </List>
      </Box>
    </Box>
  );
  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open={props.isSidebarOpen}
        variant="persistent"
        PaperProps={{
          sx: {
            width: SidebarWidth,
          },
        }}
      >
        {SidebarContent}
      </Drawer>
    );
  }


  return (
    <Drawer
      anchor="left"
      open={props.isMobileSidebarOpen}
      onClose={props.onSidebarClose}
      PaperProps={{
        sx: {
          width: SidebarWidth,
        },
      }}
      variant="temporary"
    >
      {SidebarContent}
    </Drawer>
  )
}


