import { createSlice } from '@reduxjs/toolkit';

const initialState = [
    {
     _id: "0000000000000000001",
     nombre: "inicial",
     direccion: "1",
     geo: "1",
     telefono: "1",
     fechainstala: "1",
     detalle: "1",
     equipo: "1",
     nroorden: "0",
    }
 ];

export const cuentaSlice = createSlice({
    name: 'cuentasSlice',
    initialState,
    reducers: {
        addCont: (state, action) => {
            let ixs = state.length-1;
                let ixa = action.payload.length;
                var rec = ixa-ixs;
                if (rec > 0) {
                    for (let i = ixs; i < ixa; i++) {
                        let contadd = action.payload
                        //console.log('estado addTask', postadd[i] );
                        //state.every(postadd[i]);
                        //state.push(postadd[i]);                         
                        state.push(contadd[i]);
                    }
                }},
        editCuenta: (state, action) => {
            const { id, nombre, direccion, xgeo, ygeo, telefono, fechainstala, detalle, equipo, mantenimiento, nroorden, habilitado } = action.payload;
            //console.log('action', action.payload.nroorden);            
            const foundCuenta = state.find((cuenta) => cuenta._id === id);
            let item = JSON.parse(JSON.stringify(foundCuenta))   
            if (foundCuenta) {
                foundCuenta.nombre = nombre;
                foundCuenta.nroorden = nroorden;
                foundCuenta.direccion = direccion;
                foundCuenta.xgeo = xgeo;
                foundCuenta.ygeo = ygeo;
                foundCuenta.telefono = telefono;  
                foundCuenta.fechainstala = fechainstala; 
                foundCuenta.detalle = detalle;
                foundCuenta.equipo = equipo;
                foundCuenta.mantenimiento = mantenimiento;
                foundCuenta.habilitado = habilitado
            }   
        },
        resetCuenta: ( state ) => 
                    initialState,   
        }}); 

export const { addCont, editCuenta, resetCuenta } = cuentaSlice.actions;
export const selectCuenta = (state) => state.cuentasState;
export default cuentaSlice.reducer