import React, { useEffect, useState } from 'react';
import { Box, Button, TextField,  useColorScheme, useTheme, Typography, MenuItem } from "@mui/material";

export function SetItem() {

  const [ Data, setData ] = useState()
  var TokenPc =''

  const DataBack = async () => {
    const response = await fetch('/api/data');
    const result = await response.json();

    console.log(result)

    if (Data !== null) {
      TokenPc = result.API_KEY
    }    
  }

  console.log(TokenPc)

  useEffect(() => {
    DataBack();
  }, [])

  
    
    //const PcId = 'PC escritorio';

    const preskey = () => {
      localStorage.setItem('tokenPc', TokenPc);
      console.log('grabado')
    }

    

  return (
    <Box>
        <Button 
              onClick={()=> preskey()}
              >Set Token</Button>
            <br/>        
        Token guardado
    </Box>
  )
}


