import React, {useState, useEffect } from 'react';
import { usePosts } from '../../../../context/PostContext';
import { useSelector } from 'react-redux';
import { selectCuenta } from '../../../../features/cuentas/cuentaSlide';
import { Box, Button, Card, Grid, Paper, TextField, useColorScheme, useTheme, Typography, MenuItem } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import SignalWifiStatusbarConnectedNoInternet4OutlinedIcon from '@mui/icons-material/SignalWifiStatusbarConnectedNoInternet4Outlined';
import SignalWifiConnectedNoInternet4OutlinedIcon from '@mui/icons-material/SignalWifiConnectedNoInternet4Outlined';
import ModeStandbyOutlinedIcon from '@mui/icons-material/ModeStandbyOutlined';
import { green, red } from '@mui/material/colors';
import { pubpush }  from '../../../../api/mqtt';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import RemoveCircleOutlinedIcon from '@mui/icons-material/RemoveCircleOutlined';
import { selectPost } from '../../../../features/post/postSlide';
import { getPostRequest, getPotsRequest } from "../../../../api/post";

export function BoxSeguridades({results}) {

    const posts = useSelector(selectPost) 
    const cuentas = useSelector(selectCuenta);
    const [chartData, setChartData]  = useState({
        pSeg : [],
        
    });
    
    const cuentaResult = cuentas.filter(x => x._id === results)     
    const postResult = posts.filter(x => x.topiccuenta === cuentaResult[0].nroorden)

    const message = ['TVE/2901/setting', 'enable:1'];
    const message1 = ['TVE/2901/setting', 'enable:0'];
    
    const Chart = async () => {
        let pSeg = [1];
        let line = '';
        let dateOnline0 = '';
        let dateOnline1 = '';
        let cmarcha = '';
        let estadoCheck = '';
        let status = '';
        let cr = '';
        let dateOnSeg0= '';
        let dateOnSeg1 = '';
        let fline = [];

        const postChart = await getPotsRequest();
        const postChartData = postChart.data

        if (postResult.length > 0)  {
            var postResultSeg = postChartData.filter(x =>  x.topiccuenta === postResult[0].topiccuenta)
        } else {
            var postResultSeg = [];
        }        

        const postResultSeg1 = postResultSeg.filter(x =>  x.topicevento === "seguridades" )
        const postResultSegLength = postResultSeg1.length
        const segR1 = postResultSeg1.slice((postResultSegLength,postResultSegLength-1))   
        for (let index = 2; index < 15; index++) {  
            let x = segR1[0].message & (2 ** (index));
            pSeg.push(x);    
            //console.log(pSeg)
        }

                
        const onlineData1 = postResultSeg.filter(x =>  x.topicevento === "online" )
        const onlineDataleng = onlineData1.length
        const online1 = onlineData1.slice((onlineDataleng,onlineDataleng-1))
        var dateonline = new Date(online1[0].createdAt);
        dateOnline0 = dateonline.toLocaleDateString('en-GB'); 
        dateOnline1 = dateonline.toLocaleTimeString('sv-SE');
        line = online1[0].message   

        var dateonSeg = new Date(segR1[0].createdAt);
        dateOnSeg0 = dateonSeg.toLocaleDateString('en-GB'); 
        dateOnSeg1 = dateonSeg.toLocaleTimeString('sv-SE');
        //console.log(dateonSeg)

        

        if (line === 'online') {
              fline.push(dateOnline0,' ',dateOnline1);  
        } else {
            fline.push(dateOnSeg0, ' ',dateOnSeg1);
        }
        //console.log(fline[0], fline[1])


        const marcha = postResultSeg.filter(x =>  x.topicevento === "c_marcha" )
        const marchaleng = marcha.length
        const marcha1 = marcha.slice((marchaleng,marchaleng-1))
        cmarcha = marcha1[0].message

        const tiempo = postResultSeg.filter(x =>  x.topicevento === "s_marcha" )
        const tiempoleng = tiempo.length
        const tiempo1 = tiempo.slice((tiempoleng ,tiempoleng-1))
        const Tmarcha = tiempo1[0].message
        const TmarchaMinutos =  Math.floor(Tmarcha / 60)
        const Tmarchasegundos = Tmarcha % 60;
        const TmarchaHoras = Math.floor(TmarchaMinutos / 60)
        const TmarchaMinutos1 = TmarchaMinutos % 60;

        //const checkCuenta = posts.filter(x => x.topiccuenta === cuentaResult[0].nroorden)  
        const checkEstado = postResultSeg.filter(x => x.topicevento === 'enable')
        const LengthE = (checkEstado.length)
        const CheckEstadoFinal = checkEstado.slice((LengthE ,LengthE-1))
        estadoCheck = CheckEstadoFinal[0].messagetext

        const statusR = postResultSeg.filter(x => (x.topicevento === "status"  ))
        const LengthS = (statusR.length)
        const statusRFinal = statusR.slice((LengthS ,LengthS-1))
        status = statusRFinal[0].messagetext
        //statusRFinal[0].messagetext

        const resultC = postResultSeg.filter(x => (x.topicevento === "cr"  ))    
        const LengthC = (resultC.length)
        const resultCFinal = resultC.slice((LengthC ,LengthC-1)) 
        const crArray =  resultCFinal[0].messagetext
        cr = crArray.split(',')

        // 
        //console.log(cr)

        setChartData({
            pSeg,
            line,
            dateOnline0,
            dateOnline1,
            cmarcha,
            TmarchaHoras,
            TmarchaMinutos1,
            Tmarchasegundos,
            estadoCheck,
            status,
            cr,
            fline,
        }) 
        };
    
    //console.log(chartData.fline)

    useEffect(() => {
        const timer = setInterval( async () => {
          Chart();
        }, 5000);
        return () => clearInterval(timer);
      }, []);

    const notify = () => toast.warn("Esta Seguro de la accion", 
    {
        position: "top-center",
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "colored",
        });

    return (
        <Box
            height={380} 
            width ={412} 
            >
                <Paper elevation={24} square={false}>
                    { postResult.length > 0 ?  
                        <Grid container>
                            <Box item xs={12} height={450}>
                                <Grid
                                    container
                                    direction="column"
                                    justifyContent="flex-start"
                                    alignItems="flex-start"
                                    >
                                    
                                        <Grid paddingLeft={1} >
                                        <Box
                                            sx={{                    
                                                '& > :not(style)': {                    
                                                    width: 396,
                                                    height: 40,
                                                },
                                            }}
                                                marginTop={1}
                                            >
                                                <Paper variant="outlined" square={false}>
                                                    <Grid
                                                         container
                                                         direction="row"
                                                         justifyContent="space-evenly"
                                                         alignItems="center" 
                                                        >
                                                            <Box>
                                                             {(chartData.line === 1)? <SignalWifiStatusbarConnectedNoInternet4OutlinedIcon sx={{ color: green[200], fontSize: 30 }}/>:
                                                                    <SignalWifiConnectedNoInternet4OutlinedIcon sx={{ color: red[200], fontSize: 30 }}/> }
                                                            </Box>
                                                            <Box>
                                                                {(chartData.line === 1)? <Typography variant="subtitle2">ONLINE</Typography>:
                                                                    <Typography variant="subtitle2" >OFFLINE</Typography>}
                                                            </Box>
                                                            <Box>
                                                                <Typography color='secondary' variant="subtitle2"> {chartData.fline}  </Typography>
                                                            </Box>
                                                            
                                                            
                                                            
                                                             
                                                    </Grid>
                                                </Paper>
                                            </Box>
                                        </Grid>
                                        <Grid paddingLeft={1} >
                                            <Box
                                                sx={{                    
                                                    '& > :not(style)': {                    
                                                        width: 396,
                                                        height: 90,
                                                    },
                                                }}
                                                    marginTop={1}
                                                >
                                                    <Paper variant="outlined" square={false}>
                                                        
                                                    <Grid
                                                            container
                                                            direction="row"
                                                            justifyContent="space-around"
                                                            alignItems="center"
                                                            padding={1}
                                                            >
                                                            <Grid item xs={1} paddingLeft={2}>
                                                                {(chartData.pSeg[8] > 0)? <ModeStandbyOutlinedIcon sx={{ color: red[200], fontSize:20 }}/>:
                                                                <ModeStandbyOutlinedIcon sx={{ color: green[200], fontSize: 20 }}/> }
                                                            </Grid>
                                                            <Grid item xs={5} paddingLeft={2}><Typography variant="subtitle2">Final Subida</Typography> </Grid> 

                                                            <Grid item xs={1} paddingLeft={2}>
                                                                {(chartData.pSeg[9] > 0)? <ModeStandbyOutlinedIcon sx={{ color: red[200], fontSize:20 }}/>:
                                                                <ModeStandbyOutlinedIcon sx={{ color: green[200], fontSize: 20 }}/> }
                                                            </Grid>
                                                            <Grid item xs={5} paddingLeft={2}><Typography variant="subtitle2">Final Bajada</Typography> </Grid> 
                                                            
                                                            <Grid item xs={1} paddingLeft={2}>
                                                                {(chartData.pSeg[10] > 0)? <ModeStandbyOutlinedIcon sx={{ color: red[200], fontSize:20 }}/>:
                                                                <ModeStandbyOutlinedIcon sx={{ color: green[200], fontSize: 20 }}/> }
                                                            </Grid>
                                                            <Grid item xs={5} paddingLeft={2}><Typography variant="subtitle2">Barral Izquierdo</Typography> </Grid>  
                                                            
                                                            <Grid item xs={1} paddingLeft={2}>
                                                                {(chartData.pSeg[11] > 0)? <ModeStandbyOutlinedIcon sx={{ color: red[200], fontSize:20 }}/>:
                                                                <ModeStandbyOutlinedIcon sx={{ color: green[200], fontSize: 20 }}/> }
                                                            </Grid>
                                                            <Grid item xs={5} paddingLeft={2}><Typography variant="subtitle2">Barral Derecho</Typography> </Grid> 
        
                                                            <Grid item xs={1} paddingLeft={2}>
                                                                {(chartData.pSeg[12] > 0)? <ModeStandbyOutlinedIcon sx={{ color: red[200], fontSize:20 }}/>:
                                                                <ModeStandbyOutlinedIcon sx={{ color: green[200], fontSize: 20 }}/> }
                                                            </Grid>
                                                            <Grid item xs={5} paddingLeft={2}><Typography variant="subtitle2">Doble Fondo</Typography> </Grid> 

                                                            <Grid item xs={1} paddingLeft={2}>
                                                                {(chartData.pSeg[13] > 0)? <ModeStandbyOutlinedIcon sx={{ color: red[200], fontSize:20 }}/>:
                                                                <ModeStandbyOutlinedIcon sx={{ color: green[200], fontSize: 20 }}/> }
                                                            </Grid>
                                                            <Grid item xs={5} paddingLeft={2}><Typography variant="subtitle2">Final de Pasada</Typography> </Grid>  

                                                    </Grid>
                                                    
                                                </Paper>
                                                <br/>
                                                
                                            </Box>
                                        </Grid>   
                                </Grid>


                                <Grid marginLeft={1}>
                                    <Paper variant="outlined" square={false} >
                                        <Grid marginTop={1} marginLeft={2}>
                                                    
                                                        <Grid
                                                            container
                                                            direction="column"
                                                            justifyContent="center"
                                                            alignItems="flex-start"
                                                            >

                                                                <Box
                                                                    marginTop={2}
                                                                    marginLeft={2}
                                                                >
                                                                    <Grid container spacing={2}>
                                                                        <Box><Typography variant="subtitle2">Habilitado</Typography></Box>
                                                                        
                                                                        <Box
                                                                            marginLeft={1}
                                                                        >
                                                                            {(chartData.estadoCheck === 0)? <ModeStandbyOutlinedIcon sx={{ color: red[200], fontSize:20 }}/>:
                                                                                    <ModeStandbyOutlinedIcon sx={{ color: green[200], fontSize: 20 }}/> }
                                                                        </Box>
                                                                    </Grid>
                                                                </Box>
                                                                <Box>
                                                                    <Typography variant="subtitle2" gutterBottom >Estado: {chartData.status}</Typography>
                                                                </Box>
                                                                <Box><Typography variant="subtitle2" >Marcha: {chartData.cmarcha} veces</Typography></Box>
                                                        
                                                                <Box><Typography  variant="subtitle2" >Tiempo: {chartData.TmarchaHoras}:{chartData.TmarchaMinutos1}:{chartData.Tmarchasegundos} Hs.</Typography></Box>
                                                    
                                                                <Box><Typography  variant="subtitle2" >CR1: {chartData.cr[0]} </Typography></Box>
                                                                <Box><Typography  variant="subtitle2" >CR2: {chartData.cr[1]} </Typography></Box>
                                                                <Box><Typography  variant="subtitle2" >CR3: {chartData.cr[2]} </Typography></Box>
                                                                <Box><Typography  variant="subtitle2" >CR4: {chartData.cr[3]} </Typography></Box>
                                                                
                                                                <Box
                                                                    marginBottom={2}
                                                                >
                                                                    <Grid
                                                                        container
                                                                        direction="row"
                                                                        justifyContent="center"
                                                                        alignItems="center"
                                                                    >
                                                                        <Box
                                                                            marginLeft={10}
                                                                        >
                                                                            <Button  
                                                                                variant="contained"
                                                                                startIcon={<RemoveCircleOutlineOutlinedIcon />}
                                                                                disabled={chartData.estadoCheck} 
                                                                                onClick={() => { 
                                                                                pubpush(message) 
                                                                                notify()                                                                        
                                                                            }}>Hab</Button> 
                                                                        </Box>
                                                                        <Box
                                                                            marginLeft={3}
                                                                        >
                                                                            <Button 
                                                                                variant="contained" 
                                                                                startIcon={<RemoveCircleOutlinedIcon />}
                                                                                disabled={!chartData.estadoCheck} 
                                                                                onClick={() => { 
                                                                                pubpush(message1) 
                                                                                notify()
                                                                            }}>Des</Button> 
                                                                        </Box>
                                                                    </Grid>                                                                    
                                                                </Box>
                                                            </Grid>
                                        </Grid>               
                                    </Paper>
                                        <ToastContainer 
                                                position="top-center"
                                                autoClose={false}
                                                newestOnTop
                                                closeOnClick={false}
                                                rtl={false}
                                                pauseOnFocusLoss={false}
                                                draggable={false}
                                                theme="colored"
                                            />
                                </Grid>    
                            </Box>
                        </Grid>
                    : 
                    <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    >    
                        <Grid marginY={5}></Grid>
                        <Grid marginY={5}>SIN ALARMAS</Grid>
                        <Grid marginY={5}><CircularProgress /></Grid>
                    </Grid>} 
                </Paper>
        </Box>
      )
    }
    
    
    

