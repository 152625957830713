import { useEffect, useState } from "react";
import { createContext, useContext } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { selectVariable, addVariable } from "../features/variables/variableSlide";
import { getVariablesRequest, getVariableRequest, updateVariableRequest, createVariableRequest  } from "../api/variable";

const VariableContext = createContext();

export const useVariables = () => {
  const context = useContext(VariableContext);
  if(!context) {
    throw new Error("useVariable must be used within a VariableProvider")
  }
  return context;
}

export function VariableProvider({ children }) {
  const [variable, setVariable] = useState([]);
  const variables = useSelector(selectVariable);
  const [ vbateria, setVbateria ] = useState({valor: 10}); 

  const getVariables = async () => {
    try {
      const res = await getVariablesRequest() 
      const alarm = res.data.filter(x => x.definicion ===  'alarmaMinimo' )
      const valor = alarm[0].valor  
      //console.log('ger', valor)    
      setVariable(res.data)
      setVbateria(valor);
    } catch (error) {
      console.log('error getVariable:', error);
    }
  }
   
  const getVariable = async (id) => {
    try {
      //console.log(id)
      const res = await getVariableRequest(id);
      return res.data
    } catch (error) {
      console.log(error)
    }
  }

  const updateVariable = async (id, variable) => {
    try {
      const res = await updateVariableRequest(id, variable);
      setVariable(variable.map((variable) => (variable._id === id ? res.data : variable)));
    } catch (error) {
      console.error(error)
    }      
  }

  const createVariable = async (variable) => {
    try {
      //console.log('contx cuenta:', cuenta)
      const res = await createVariableRequest(variable)
      console.log('contx create job:', res, variable)
    } catch (error) {
      console.error('error en conexto:', error);
    }
  }

  return (
    <VariableContext.Provider value={{ 
      variable,
      vbateria,
      getVariable, 
      getVariables,
      updateVariable,
      createVariable      
    }}>
      {children}
    </VariableContext.Provider>
  )
}

