import React, { useEffect, useState } from 'react';
import { Typography }from "@mui/material";
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from "react-router-dom";
import Page from '../../image/page.jpg';
import { useAuth } from '../../context/AuthContext';
import { useDispatch } from 'react-redux';
import { resetCuenta } from "../../features/cuentas/cuentaSlide";
import { resetJob } from "../../features/jobs/jobSlide";
import { resetAlarm } from "../../features/alarms/alarmSlide";
import { resetVariable } from "../../features/variables/variableSlide";
import { resetPost } from "../../features/post/postSlide";
import {
  Box,  
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  Stack
} from "@mui/material";

export function LoginPage() {
  const {register, handleSubmit, formState:{ errors }} =  useForm();  
  const { signin, errors: signinErrors, isAuthenticated, user } = useAuth();
  const [ tokenVal, setTokenVal ] = useState()
  const [ Data, setData ] = useState({})  
  const navigate = useNavigate();
  const dispatch = useDispatch(); 

  var isLogin = false
  
  const onSubmit = handleSubmit((data) => {
    //console.log(data);
    //dispatch(resetPost());
    dispatch(resetCuenta());
    dispatch(resetJob());   
    dispatch(resetVariable());
    dispatch(resetAlarm());
    dispatch(resetPost());
    signin(data);    
  });


  //secuencia de token
  const DataBack = async () => {
    const response = await fetch('/api/data');
    const result = await response.json();
    setData(result);
  }
  if (tokenVal === Data.API_KEY || Data.LOGIN === 'login') {
    //console.log('login')
    isLogin = true
  } else {
    //console.log('no login')
    isLogin = false
  }
  useEffect(() => {
    if (isAuthenticated) navigate('/home');
    const tokenRet = localStorage.getItem('tokenPc'); 
    setTokenVal(tokenRet) 
    DataBack();
   }, [isAuthenticated]);

   //console.log('data', Data.LOGIN, Data.API_KEY, tokenVal)
  
  return (    
    <Grid
      container
      direction="column"
      sx={{
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {/* banda superior */}
      <Box
        height={40}
      >
        <Grid
          container
          direction="row"
          sx={{
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box bgcolor='#e57373'></Box>
          <Box bgcolor='#e57373'></Box>
          <Box bgcolor='#e57373'></Box>
        </Grid>
      </Box>


      {/* banda media */}
      <Box
        height={200}
      >
        <Grid
          container
          direction="row"
          sx={{
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box bgcolor='#e57373'></Box>
          <Box bgcolor='#e57373' width={440}>
            <Card >
              <CardMedia
              component="img"
              alt="green iguana"
              height="140"
              image={Page}
              />
                <CardContent>
                  <Typography variant="h6" color="warming">
                    { signinErrors.map((error, i) => (<div key ={i}>{error}</div>))}
                  </Typography>
                  <Typography variant="h4" color="primary">Login</Typography>  
                  <form onSubmit={onSubmit}>  
                    <Grid
                      container
                      direction="column"
                      justifyContent="space-evenly"
                      alignItems="center"
                    >
                      <br/> 
                      <input type='email' 
                      {...register('email', {required: true})} placeholder='email'/>
                      {errors.email?.message && (
                          <Typography variant="h3" color="warming"
                          >{errors.email?.message}</Typography>            
                        )}
                      <br/>  
                      <input type='password' 
                            {...register('password', {required: true})} placeholder='password'/>
                          {errors.password?.message && (
                              <Typography variant="h3" color="red"
                              >{errors.password?.message}</Typography>           
                            )}
                      <br/> 
                      

                      { (!isLogin)? 
                        <Button 
                          type='submit'
                          variant="outlined"
                          disabled={true}
                          sx={{ mx: 2, my: 2 }}
                          >Login</Button>
                        :                
                        <Button 
                          type='submit'
                          variant="outlined"
                          sx={{ mx: 2, my: 2 }}
                          disabled={false}
                          >Login</Button>
                        }












                      </Grid>  
                  </form>
                </CardContent>


            </Card>




          </Box>
          <Box bgcolor='#e57373' width={100}></Box>
        </Grid>
      </Box>

      {/* banda inferior */}
      
      

      

    </Grid>

    
    

    
    
  )
}


