import { createSlice } from '@reduxjs/toolkit';

const initialState = [
  
  {
    _id: "000000000000001",
  definicion: 'min',
  valor: 1,
  texto:'1'
  }
];

  export const variableSlice = createSlice({
    name: 'variablesSlice',
    initialState,
    reducers: {
      addVariable: (state, action) => {
        let ixs = state.length-1;
                let ixa = action.payload.length;
                var rec = ixa-ixs;
                if (rec > 0) {
                    for (let i = ixs; i < ixa; i++) {
                        let contadd = action.payload
                        //console.log('estado addTask', postadd[i] );
                        //state.every(postadd[i]);
                        //state.push(postadd[i]);                         
                        state.push(contadd[i]);
                    }
                }
      },
      editVariable: (state, action) => {
        const { id, definicion, valor, texto } = action.payload;
        //console.log('action', action.payload.nroorden);            
        const foundVariable = state.find((x) => x._id === id);
        let item = JSON.parse(JSON.stringify(foundVariable))   
        if (foundVariable) {
            foundVariable.definicion = definicion;
            foundVariable.valor = valor;
            foundVariable.texto = texto;            
        }   
        },
        resetVariable : ( state ) => 
            initialState,       
    }
      
  });

export const { addVariable, resetVariable, editVariable } = variableSlice.actions;
export const selectVariable = (state) => state.variablesState;
export default variableSlice.reducer



  