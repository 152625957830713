import axios from './axios';

export const getVariablesRequest = () => axios.get('/variables');

export const getVariableRequest = (id) => axios.get(`/variables/${id}`);

//export const getCuentaRequestUser = () => axios.get('/cuentasuser');

export const createVariableRequest = (variable) => axios.post('/variables', variable);

export const updateVariableRequest = (id, variable) => axios.put(`/variables/${id}`, variable);



//export const deleteCuentaRequest = (id) => axios.delete(`/cuentas/${id}`);