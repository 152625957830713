import { createContext, useContext, useEffect, useState } from "react";
import { getPostRequest, getPotsRequest } from "../api/post";

const PostContext = createContext();

export const usePosts = () => {
    const context = useContext(PostContext);
    if(!context) {
      throw new Error("usePost must be used within a CuentaProvider")
    }
    return context;
  }

  export function PostProvider({ children }) {
    const [posts, setPosts] = useState([]);

    
    useEffect(() => {
      (async () => {
        const res = await getPotsRequest();
        setPosts(res.data);
      })();
    }, []);
  
    const getPosts = async () => {
      try {
        const res = await getPotsRequest()
        //console.log('contx gets  cuenta:', res)
        setPosts(res.data);
      } catch (error) {
        console.log('error getPots:', error);
      }
    }    
  
    const getPost = async (id) => {
      try {
        const res = await getPostRequest(id);
        return res.data
      } catch (error) {
        console.log(error)
      }
    }
  
    
    return (
      <PostContext.Provider value={{ 
        getPost,
        posts,
        getPosts
      }}>
        {children}
      </PostContext.Provider>
    )
  }
  