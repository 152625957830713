import { configureStore } from '@reduxjs/toolkit';
import postsReducer from '../features/post/postSlide';
import cuentasReducer from '../features/cuentas/cuentaSlide';
import jobsReducer from '../features/jobs/jobSlide';
import checksReducer from '../features/checker/chekerSlide';
import variablesReducer from '../features/variables/variableSlide';
import alarmsReducer from '../features/alarms/alarmSlide';
import storage from 'redux-persist/lib/storage';
import { persistReducer} from 'redux-persist';
import { combineReducers } from '@reduxjs/toolkit';

//import { combineReducers } from 'redux';
import thunk from 'redux-thunk';

const persistConfig = {
    key: 'root',
    storage,    
}

const rootReducer = combineReducers({
    postsState: postsReducer,
    jobsSate: jobsReducer,
    cuentasState: cuentasReducer,
    checksState: checksReducer,
    variablesState: variablesReducer,
    alarmsState: alarmsReducer,
})

export const persistedReducer = persistReducer( persistConfig, rootReducer );

export const store = configureStore({    
        reducer: persistedReducer,
        middleware: [thunk],
       
});






