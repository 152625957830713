import React, {useState, useEffect } from 'react';
import { Box, Button, Card, Grid, Paper, TextField, useColorScheme, useTheme, Typography, MenuItem, Switch } from "@mui/material";
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectCuenta } from '../../../features/cuentas/cuentaSlide';
//import { pubpush }  from '../../../../api/mqtt';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export function BoxCuenta({results}) {
    
    const navigate = useNavigate(); 
    const cuentas = useSelector(selectCuenta);    
    const cuentaResult = cuentas.filter(x => x._id === results)  ;

    return (<>    
    <Box
        padding={1}       
    >
      <Grid 
        container
        direction="column"
        justifyContent="center"
        alignItems="flex-start" 
        //marginTop={3}                   
        >
            <Grid>
                <Typography  variant="subtitle2" color='primary' gutterBottom >
                {cuentaResult[0].nroorden}      {cuentaResult[0].nombre}
                </Typography>
            </Grid>
            <Grid >
                <Typography  variant='body2'  gutterBottom>Direccion: {cuentaResult[0].direccion}</Typography>
            </Grid>            
                <Box>
                    <Typography variant='body2'  gutterBottom>Telefono: {cuentaResult[0].telefono}</Typography>
                </Box>
                <Box>
                    <Typography variant='body2'  gutterBottom>Equipo: {cuentaResult[0].equipo}</Typography>
                </Box>           
            <Grid >
                <Typography  variant='body2'  gutterBottom>Mantenimiento: {cuentaResult[0].mantenimiento} </Typography>
            </Grid> 
            
        </Grid>  
    </Box>         
    </>);
  }


