import React from 'react';
import { Box, Grid, Button, Paper, Typography } from "@mui/material";
import GaugeVbat from '../Graph/GaugeVbat';
import Corriente from '../Graph/Corriente';
import Voltaje from '../Graph/Voltaje';

export function BoxVoltaje({volt, vbat, i1m, xvolt}) {

    //console.log(volt, vbat, i1m, xvolt)
  return (
    <Box
        //backgroundColor='#ffcdd2'        
    >
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="stretch"
        >            
            <Box
                marginLeft={4}
            >
                <Corriente  i1m={i1m} xvolt={xvolt} volt={volt} />                
            </Box>
        </Grid>

    </Box>
  )
}



