import React, {useEffect, useState} from 'react';
import {
  Box,  
} from "@mui/material";
import { useNavigate, Link, useParams } from 'react-router-dom';
import { 
  GridRowModes,
  DataGrid, 
  GridToolbar,
  GridToolbarContainer, 
  GridActionsCellItem,
  GridRowEditStopReasons,
} from '@mui/x-data-grid';
import { useAuth } from '../../context/AuthContext';



export function UsuarioGral() {
  
  
  const { getUsers, users } = useAuth();
  
  const navigate = useNavigate(); 
  const [rowId, setRowId] = useState(null);
  const params = useParams();

  useEffect(() => {
    getUsers();    
    //console.log(cuentas)
  }, [])
  //console.log(users)

  const columns = [    
    {
      field: "createdAt",
      headerName: "Fecha",
      valueFormatter: params => new Date(params?.value).toLocaleString('en-GB'),
      width:180,      
    },
    {
      field: "username",
      headerName: "Usuario",
      width:150,       
    },
    
    {
      field: "role",
      headerName: "Rol",
      width:150,       
    },
  ]

  return (
    <Box
      //bgcolor='pink'
      flex={4}
      height={500}
      width={700}
      marginLeft={30}
      marginTop={10}
    ><DataGrid
      rows={users} 
      columns={columns} 
      
      getRowId={(row) => row._id}  
       
    />
      
    </Box>
  )
}


