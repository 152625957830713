import React from 'react';
import { useSelector } from 'react-redux';
import { useEffect, useState } from "react";
import { Box, Grid, Paper } from "@mui/material";
import { 
    GridRowModes,
    DataGrid, 
    GridToolbar,
    GridToolbarContainer, 
    GridActionsCellItem,
    GridRowEditStopReasons,
  } from '@mui/x-data-grid';
  import { DataGridPro } from '@mui/x-data-grid-pro'; 
  import { selectJob } from '../../features/jobs/jobSlide';
  
  export function VisorJob() {

    const jobs = useSelector(selectJob)
        
    const postJob = jobs.filter(x => (x.dispositivo !== "nunca"  ))
    console.log(postJob)

    //const postVisor9999 = postVisor.filter(x => (x.topiccuenta === "9999"  ))
    //console.log(postVisor9999)

    const columns = [    
      {
        field: "createdAt",
        headerName: "Fecha",
        valueFormatter: params => new Date(params?.value).toLocaleString('en-GB'),
        width:170,      
      },
      {
        field: "dispositivo",
        headerName: "Dispositivo",
        width:300,  
        align:"left",   
      },  
      {
        field: "garantia",
        headerName: "Gar",
        width:40,  
        align:"left",   
      },
      {
        field: "observaciones",
        headerName: "Observaciones",
        width:300,  
        align:"left",   
      },   
      {
        field: "tecnico",
        headerName: "Tecnico",
        width:150,  
        align:"left",   
      },    
       
    ]

    const [sortModel, setSortModel] = useState([
      {
        field: 'createdAt',
        sort: 'desc',
      },
    ]);

    return (
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="flex-start"
        marginLeft={2}
        marginTop={2}
        >
           
           <Paper elevation={20} square={false} >
                
                <Box
                    marginX={5}
                    marginY={1}
                    sx={{ height: 700, width: 1000 }}
                >
                    <DataGrid
                        rows={postJob} 
                        columns={columns}  
                        slots={{ toolbar: GridToolbar }}
                        rowHeight={18}
                        getRowId={(row) => row._id} 
                        sortModel={sortModel}                        
                        onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
                    />
                </Box>
                </Paper>
    </Grid>
    )
  }
  
 
  