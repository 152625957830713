import React from 'react';
import { Formik, Form, Field, ErrorMessage } from "formik";
import { getCuentaRequest, createCuentaRequest, updateCuentaRequest } from "../../api/cuenta";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Button, TextField, useColorScheme, useTheme, Typography, MenuItem } from "@mui/material";
import { useDispatch} from "react-redux";
import { editCuenta } from "../../features/cuentas/cuentaSlide";
import { useAuth } from "../../context/AuthContext";

const equipos = [
  {
    value: "PO",
    label: "PO",
  },  
  {
    value: "POA",
    label: "POA"
  }, 
  {
    value: "PT",
    label: "PT"
  },  
  {
    value: "SRN",
    label: "SRN"
  },  
  {
    value: "PVH",
    label: "PVH"
  },  
  {
    value: "PVHPA",
    label: "PVHPA"
  },  
]

const mantenimiento = [
  {
    value: "no",
    label: "no",
  }, 
  {
    value: "Mensual",
    label: "Mensual",
  }, 
  {
    value: "Bimensual",
    label: "Bimensual",
  },
  {
    value: "Trimensual",
    label: "Trimensual",
  },
  {
    value: "Semestral",
    label: "Semestral",
  },
]

const habilitacion = [
  
  {
    value: "desa",
    label: "Desabilitado",
  },
  {
    value: "habi",
    label: "Habilitado",
  },
]

export function CuentaForm() {

  const navigate = useNavigate(); 
  const [ cuenta, setCuenta ] = useState({
    nombre: "",
    direccion: "",
    xgeo: "",
    ygeo: "",
    telefono: "",
    fechainstala: "",
    detalle: "",
    equipo: "",
    mantenimiento: "",
    nroorden: "",
    habilitado: "",
      })
  const params = useParams();
  
  const dispatch = useDispatch();  
  const { user } = useAuth();

  

  useEffect(() => {
    (async () => {
      if (params.id) {
        const res = await getCuentaRequest(params.id);
        console.log('cuentas', res.data)
        const cuenta = res.data
        setCuenta({
          nombre: cuenta.nombre,      
          direccion: cuenta.direccion,
          geo: cuenta.geo,
          xgeo: cuenta.xgeo,
          ygeo: cuenta.ygeo,
          telefono: cuenta.telefono,      
          fechainstala: cuenta.fechainstala,
          detalle: cuenta.detalle,      
          equipo: cuenta.equipo,   
          nroorden: cuenta.nroorden,
          mantenimiento: cuenta.mantenimiento,
          hbilitado: cuenta.hbilitado,
          user: user.id,      
        });
      }
    })();
  }, []);
  //console.log(user)
    
  return (
    <Box 
      marginTop={5} 
      maxWidth={800}
    >
      <Box ><Typography variant="h5" color="primary" >Ingrese nuevo cliente</Typography></Box>
      <Formik
        initialValues={cuenta}
        enableReinitialize
        onSubmit={async (values, actions) => {  
          if (params.id) {
            
            updateCuentaRequest(params.id, values)
            setCuenta(values);
            //console.log(params.id, values);
            dispatch(editCuenta({ ...values, id: params.id }));

          } else {
            //console.log(values);
            createCuentaRequest(values)
          }
            actions.resetForm();
            actions.setSubmitting(false);
            navigate('/cuenta');
          }}
      >   
        {({
            setFieldValue, 
            isSubmitting, 
            handleSubmit, 
            handleChange, 
            handleBlur,
            values 
        }) => (
          <Form onSubmit={handleSubmit}>
            <Box
                  m="40px"
                  display="grid"
                  gap="30px"
                >
                  <TextField
                        fullWidth
                        variant="filled"
                        type="text"
                        label="Nombre"                       
                        onChange={handleChange}
                        value={values.nombre}
                        name="nombre"
                        sx={{ gridColumn: "span 4" }} 
                        autoFocus={true}                      
                      />
                  <TextField
                        fullWidth
                        variant="filled"
                        type="text"
                        label="Nro de Orden"                       
                        onChange={handleChange}
                        value={values.nroorden}
                        name="nroorden"
                        sx={{ gridColumn: "span 1" }}                                              
                      />
                  <TextField
                        fullWidth
                        variant="filled"
                        type="text"
                        label="Direccion"                       
                        onChange={handleChange}
                        value={values.direccion}
                        name="direccion"
                        sx={{ gridColumn: "span 1" }}                                              
                      />
                  <TextField
                        fullWidth
                        variant="filled"
                        type="text"
                        label="Telefono"                       
                        onChange={handleChange}
                        value={values.telefono}
                        name="telefono"
                        sx={{ gridColumn: "span 1" }}                                              
                      />
                  <TextField
                        fullWidth
                        variant="filled"
                        type="text"
                        label="XGeo"                       
                        onChange={handleChange}
                        value={values.xgeo}
                        name="xgeo"
                        sx={{ gridColumn: "span 1" }}                                              
                      />
                  <TextField
                        fullWidth
                        variant="filled"
                        type="text"
                        label="YGeo"                       
                        onChange={handleChange}
                        value={values.ygeo}
                        name="ygeo"
                        sx={{ gridColumn: "span 1" }}                                              
                      />
                  <TextField
                        fullWidth
                        variant="filled"
                        type="text"
                        label="Fecha instalacion"                       
                        onChange={handleChange}
                        value={values.fechainstala}
                        name="fechainstala"
                        sx={{ gridColumn: "span 1" }}                                              
                      />
                      <TextField
                        id="outlined-select-currency"
                        select           
                        label="Habilitado"                       
                        onChange={handleChange}
                        value={values.habilitado}
                        name="habilitado"
                        sx={{ gridColumn: "span 1" }}                                              
                      >{habilitacion.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}  
                      </TextField>
                  <TextField
                        id="outlined-select-currency"
                        select                        
                        label="Equipo"                        
                        onChange={handleChange}
                        value={values.equipo}
                        name="equipo"
                        sx={{ gridColumn: "span 1" }}                                              
                      >{equipos.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}  
                  </TextField>
                  <TextField
                        id="outlined-select-currency"
                        select                        
                        label="Mantenimiento"                        
                        onChange={handleChange}
                        value={values.mantenimiento}
                        name="mantenimiento"
                        sx={{ gridColumn: "span 1" }}                                              
                      >{mantenimiento.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}  
                  </TextField>
                </Box>
                <Button 
                  type='submit'
                  variant="outlined"
                  sx={{ mx: 60, my: 2 }}
                  >Guardar</Button>
          </Form>
        )
        }
      </Formik>





    </Box>
  )
}


