import React, { useEffect, useState } from 'react';
import { Box, Button, TextField,  useColorScheme, useTheme, Typography, MenuItem } from "@mui/material";
import { Link, useNavigate } from 'react-router-dom';

export function KeyPc() {

  const navigate = useNavigate();
  const [error, setError] = useState('');
  const [ tokenPC, setTokenPC ] = useState();
  const [ Data, setData ] = useState({});

  const DataBack = async () => {
    const response = await fetch('/api/data');
    const result = await response.json();
    setData(result);
    const tokenRet = localStorage.getItem('tokenPc');  
    setTokenPC(tokenRet)   
  }    

  console.log(Data)

  useEffect(() => {
    DataBack();      
  }, [])


  return (
    <Box>
      Seleccione opcion:             
            <br/><br/>
            <Button 
               variant="outlined"
               onClick={()=> navigate(`/seti`)}      
              >Set Token</Button>
            <br/><br/>
            <Button 
              variant="outlined"
              color="secondary"
              onClick={()=> navigate(`/deli`)}
              >Del Token</Button>
            <br/><br/>
            variable storage: {tokenPC}
            <br/><br/>
            .env Key: {Data.API_KEY}
            <br/>
            .env Log: {Data.LOGIN}

            
            
    </Box>        
  )
}


