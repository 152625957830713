import React, { useEffect, useState } from 'react';
import { Box, Grid, Button, Paper, Typography, Card, CardContent } from "@mui/material";
import { useSelector, useDispatch } from 'react-redux';
import { selectPost } from '../../features/post/postSlide';
import { selectCuenta } from '../../features/cuentas/cuentaSlide';
import { useNavigate, useParams } from "react-router-dom";
import { getPostsDashRequest } from '../../api/post';
import { BoxCuenta } from './Box/BoxCuenta';
import { BoxVoltaje} from './Box/BoxVoltaje';
import { BoxJob } from './Box/BoxJob';
import { BoxSeguridad } from './Box/BoxSeguridad';
import { getVistasRequest } from '../../api/vista';


export function DashPage() {

  const dispatch = useDispatch(); 
  const post = useSelector(selectPost)
  const cuentas = useSelector(selectCuenta)
  const params = useParams();
  const [cuentaVar, setCuentaVar] = useState([]);
  const [gaugeData, setGaugeData] = useState({});
  const [vistas, setVistas] = useState([]);
  const [date0, setDate0] = useState();
  const [date1, setDate1] = useState();
  const [secs, setSecs] = useState();  
 
  const cuenta = cuentas.filter(x => (x._id === params.id  ))
  const cuentaBox = cuenta[0].nroorden
  
  const Chart = async () => {
    let vbat = 0; 
    let statusl = '';
    let charg = '';
    let ic = 0; 
    let type = '';
    let sec = 0; 
    let c_seg = 0; 
    let c_marcha = 0; 
    let error = ''; 
    let cr = ''; 
    let volt = [];
    let i1m = [];
    let xvolt = [];

    const res = await getPostsDashRequest(cuentaBox);   
    const resData = res.data
    const data = resData[0].postUnico 

    vbat = data.vbat;
    statusl = data.status;
    charg = data.charg;
    ic = data.ic; 
    type = data.type;
    sec = data.sec; 
    c_seg = data.c_seg; 
    c_marcha = data.c_marcha; 
    error = data.error; 
    cr = data.cr;  

    const dataM = resData[0].postMultiple
    const dataMFinal = dataM.length
    const dataMInicial = dataMFinal - 15

    if (dataMFinal > 10) {
      for (let i = dataMInicial; i < dataMFinal; i++) {
        volt.push(resData[0].postMultiple[i].vbat)        
        i1m.push(resData[0].postMultiple[i].ic)        
        xvolt.push(((new Date(resData[0].postMultiple[i].createdAt)).getHours()).toString() + ':' 
        + ((new Date(resData[0].postMultiple[i].createdAt)).getMinutes()).toString());    
      }      
    }    
    setGaugeData({vbat, volt, i1m, xvolt, statusl, charg, type, sec, c_seg, c_marcha, error, cr})    
  }

  const Chart2 = async () => {
    var vistasResult = ([]);
    let status = '';
    let c_seg = '';
    let c_marcha = '';
    let cr = '';
    let charge = '';
    let error = '';   

    const res = await getVistasRequest();     
    
    console.log('vista', res.data.length, cuentaBox )

    if (res.data.length > 1) {
        vistasResult = res.data.filter(x => x.topiccuenta === cuentaBox ) 
        
        console.log(vistasResult )
        if (vistasResult.length > 0) {
          const dateonline = new Date(vistasResult[0].updatedAt);
          const dateOnline0 = dateonline.toLocaleDateString('en-GB'); 
          const dateOnline1 = dateonline.toLocaleTimeString('sv-SE');
          //console.log(dateOnline0, dateOnline1)
          setDate0(dateOnline0)
          setDate1(dateOnline1)
          setSecs(vistasResult[0].sec)
          status = vistasResult[0].status
          c_seg = vistasResult[0].c_seg
          c_marcha = vistasResult[0].c_marcha
          cr = vistasResult[0].cr
          charge = vistasResult[0].charge
          error = vistasResult[0].error
          setVistas(status, c_seg, c_marcha, cr, charge, error)
        }
      } else {
        vistasResult = [];
      }
        }
        let a1 = secs & (2 ** (9))
        let a2 = secs & (2 ** (10))
        let a3 = secs & (2 ** (11))
        let a4 = secs & (2 ** (12))
        let a5 = secs & (2 ** (13))
        let a6 = secs & (2 ** (14)) 
        const statusl = gaugeData.statusl 
        const charg = gaugeData.charg
        const type = gaugeData.type
        const sec = gaugeData.sec
        const c_seg = gaugeData.c_seg
        const c_marcha = gaugeData.c_marcha
        const error = gaugeData.error
        const cr = gaugeData.cr
  
  //console.log('dash', gaugeData, vistas)

  useEffect(() => {
    Chart();
    Chart2();
  })


  return (
    <Box
        
        maxWidth={1300}
        maxHeight={600}
    >
        
            <Box
                //maxWidth={1200}
                //maxHeight={800}
            >
                <Grid
                    container
                    direction="column"
                    justifyContent="stretch"
                    alignItems="stretch"
                    >
                        {/*   superior             */}
                        <Box
                            //backgroundColor='#ffcdd2'
                            width={1300}
                                                        
                        >
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="stretch"
                                >
                                <Box
                                    //backgroundColor='#ce93d8'
                                    width={300}
                                       
                                    marginTop={4}                           
                                >
                                    <Card variant="outlined">
                                        <Box
                                            padding={2}
                                        >
                                            <BoxCuenta results={params.id} />
                                        </Box>
                                    </Card>
                                    
                                    
                                    </Box>    
                                       
                                <Box
                                    //backgroundColor='#f48fb1'
                                    width={740} 
                                    height={220}                                                                 
                                >
                                    <Grid
                                        container
                                        direction="row"
                                        sx={{
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}
                                        >
                                        <BoxVoltaje volt={gaugeData.volt} vbat={gaugeData.vbat} i1m={gaugeData.i1m} xvolt={gaugeData.xvolt}/>  
                                    </Grid>                                    
                                </Box>
                            </Grid>
                        </Box>

                        {/*   inferior             */}
                        <Box
                            //backgroundColor='#e1bee7' 
                            marginTop={2}                                                       
                        >
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="stretch"
                                >
                                <Box
                                    //backgroundColor='#4db6ac'
                                    width={360}
                                    height={320}                               
                                >
                                    <Card variant="outlined">
                                        <Box padding={2}>
                                            <BoxSeguridad result={{ date0, date1, a1, a2, a3, 
                                                  a4, a5, a6, statusl, charg, type, sec, c_seg, c_marcha, error, cr }}/>
                                        </Box>                                        
                                    </Card>                                                                    
                                </Box>
                                <Box width={20}></Box>    
                                <Box
                                    //backgroundColor='#4fc3f7'
                                    width={640}  
                                    height={320}                                                                                                       
                                >
                                    <Card variant="outlined">
                                        <Box padding={2}><BoxJob cuentaBox={params.id} /></Box>
                                    </Card> 
                                </Box>
                            </Grid>
                        </Box>
                </Grid>                
            </Box>
        
    </Box>
  )
}


