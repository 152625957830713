import React, {useState, useEffect } from 'react';
import { Box, Button, Card, Grid, Paper, TextField, useColorScheme, useTheme, Typography, MenuItem, Switch } from "@mui/material";
import { usePosts } from '../../../../context/PostContext';
import { useSelector } from 'react-redux';
import { selectCuenta } from '../../../../features/cuentas/cuentaSlide';
import { pubpush }  from '../../../../api/mqtt';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export function BoxCuenta({results}) {

    
    const cuentas = useSelector(selectCuenta);    
    //let item = JSON.parse(JSON.stringify(results)) 
    const cuentaResult = cuentas.filter(x => x._id === results)  ;
    
    
    

    return (<>      
      <Grid 
        container
        direction="column"
        justifyContent="center"
        alignItems="flex-start"   
        height ={205} 
        width = {412}              
        >
            {/* 

        <Grid>
            <Typography marginLeft={2} variant="h5" color='primary' gutterBottom paddingLeft={2}>
            {cuentaResult[0].nroorden}      {cuentaResult[0].nombre}
            </Typography>
        </Grid>
        <Grid marginLeft={3}>
            <Typography marginLeft={1} variant='subtitle2'  gutterBottom>Direccion: {cuentaResult[0].direccion}</Typography>
        </Grid>

        <Grid
            container
            direction="row"
            justifyContent="space-around"
            alignItems="center"
        >
            <Box>
                <Typography variant='subtitle2'  gutterBottom>Telefono: {cuentaResult[0].telefono}</Typography>
            </Box>
            <Box>
                <Typography variant='subtitle2'  gutterBottom>Equipo: {cuentaResult[0].equipo}</Typography>
            </Box>
            <Box></Box>
        </Grid>
        <Grid marginLeft={3}>
            <Typography marginLeft={1} variant='subtitle2'  gutterBottom>Mantenimiento: {cuentaResult[0].mantenimiento} </Typography>
        </Grid>  

                */}

    </Grid>           
    </>);
  }


