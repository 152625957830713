//<h1>{jobs.length}</h1>
import axios from '../../api/axios';
import React, {useState, useEffect } from 'react';
import { 
  Box, 
  Button,
  Card, 
  CardHeader,   
  CardContent, 
  Paper,
  Typography, FormControlLabel, Radio,
} from "@mui/material";
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectCuenta } from '../../features/cuentas/cuentaSlide';
import { selectJob } from '../../features/jobs/jobSlide';
import { useParams } from "react-router-dom";
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { BoxCuenta } from '../Cuenta/Event/Box/BoxCuenta';
import { TablaEditJob} from './Form/TablaEditJob';

export function CuentaCardOnly() {
 
  const cuentas = useSelector(selectCuenta)
  const jobs = useSelector(selectJob)
  const navigate = useNavigate(); 
  const params = useParams();
  const [ job, setJob ] = useState()  
  const [ jobr, setJobr] = useState()
  const [rowId, setRowId] = useState(null);

  
  const result = cuentas.filter(cuenta =>cuenta._id === params.id)
  


  if (result.length > 0) {
    var resultC = jobs.filter(job => job.cuentaID === result[0]._id) 
  } else {
    var resultC = 0
  }
  

    
    
  

  //console.log(result[0]._id, resultC )
  
  const columns = [        
    {
      field: "createdAt",
      headerName: "Fecha",
      width:110,
      valueFormatter: params => new Date(params?.value).toLocaleString('en-GB'),       
    },
    
    {
      field: "dispositivo",
      headerName: "Dispositivo",
      width:220,     
    },
    {
      field: "tecnico",
      headerName: "Tecnico",
      flex: 40,      
    },
    {
      field: "garantia",
      headerName: "Garantia",
      flex: 40,      
    },
    {
      field: "remito",
      headerName: "Remito",
      flex: 80,      
    },
    {
      field: "observaciones",
      headerName: "Observaciones",
      width:200,     
    },
    
  ]

  const [sortModel, setSortModel] = useState([
    {
      field: 'createdAt',
      sort: 'desc',
    },
  ]);

  return (
    <Box 
      height={500}
      width={1000}
      marginLeft={10}
    >
      <Box
        marginTop={2}
      >
        <Box><Typography  variant="h6">Cuenta: {result[0].nroorden}</Typography></Box>
        <Box><Typography  variant="h6">Nombre: {result[0].nombre}</Typography></Box>
        <Box><Typography  variant="h6">Direccion: {result[0].direccion}</Typography></Box>
        <Box><Typography  variant="h6">Tel: {result[0].telefono}</Typography></Box>
        <Box><Typography variant="h6">Fecha instalacion: {result[0].fechainstala}</Typography></Box>
      </Box>
      <Paper elevation={10} square={false} >
        <Box marginTop={4}>
                <Paper elevation={1} square={false} >
                  
                </Paper>
          </Box>  
        <Box
          padding={4}
          height={350}
          width={900}
          > 
          <DataGrid           
            rows={resultC} 
            columns={columns} 
            slots={{ toolbar: GridToolbar }}
            getRowId={(row) => row._id}
            rowHeight={19} 
            sortModel={sortModel}                        
            onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
          />
            
          
        </Box>
        <Box
          padding={2}
        >
          <Button 
                variant="outlined"
                color="secondary"                      
                startIcon={<AddCircleOutlineOutlinedIcon />} 
                size="small"
                sx={{ padding: '2',  }}
                onClick={() => {
                  console.log(params.id);
                  navigate(`/job/${params.id}`);                  
                }} 
              >Nuevo Trabajo</Button>  
        </Box>
      </Paper>
     
    </Box>
  )
}