import React from 'react';
import {
  Box, 
  Paper, 
  
} from "@mui/material";
import { MapComponent } from './Map';

export function MapPage() {
  return (
    <Box 
      height={300}
      width={1200}
      marginLeft={10}
      marginTop={2}
      //bgcolor='pink'
      >
        <Paper elevation={1} square={false} >
          <MapComponent />
        </Paper>
        
        
       
     
    </Box>
  )
}


