import React from 'react';
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState } from "react";
import { Box, Button, TextField,  useColorScheme, useTheme, Typography, MenuItem, Paper } from "@mui/material";
import { getJobRequest, createJobRequest } from "../../../api/job";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../../context/AuthContext";
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import { selectCuenta } from '../../../features/cuentas/cuentaSlide';
import { addJob } from '../../../features/jobs/jobSlide';

const dispositivo = [
  {
    value: 'BATERIAS 22 AMP CICLO PROFUNDO',
    label: 'BATERIAS 22 AMP CICLO PROFUNDO'
  },
  {
    value: 'CARGADOR 24 V. 100 WAT',
    label: 'CARGADOR 24 V. 100 WAT'
  },
  {
    value: 'GOLPE DE PUÑO',
    label: 'GOLPE DE PUÑO'
  },
  {
    value: 'LLAVE DE ENCENDIDO',
    label: 'LLAVE DE ENCENDIDO'
  },
  {
    value: 'ESCOBILLAS',
    label: 'ESCOBILLAS'
  },
  {
    value: 'PLAQUETA DE MANIOBRA',
    label: 'PLAQUETA DE MANIOBRA'
  },
  {
    value: 'PLAQUETA DE PILARES',
    label: 'PLAQUETA DE PILARES'
  },
  {
    value: 'RESORTE A GAS',
    label: 'RESORTE A GAS'
  },
  {
    value: 'PULSADOR DE LLAMADA',
    label: 'PULSADOR DE LLAMADA'
  },
  {
    value: 'SWITCH DE BARRALES',
    label: 'SWITCH DE BARRALES'
  },
  {
    value: 'SWITCH DE DOBLE FONDO',
    label: 'SWITCH DE DOBLE FONDO'
  },
  {
    value: 'FINALES DE CARRERA',
    label: 'FINALES DE CARRERA'
  },
  {
    value: 'BATERIAS 12 V. 7 AMP',
    label: 'BATERIAS 12 V. 7 AMP'
  },
  {
    value: 'CARGADOR 24 V. 60 WATT',
    label: 'CARGADOR 24 V. 60 WATT'
  },
  {
    value: 'PULSADOR DE APOYA BRAZOS',
    label: 'PULSADOR DE APOYA BRAZOS'
  },
  {
    value: 'CONTROLES REMOTO',
    label: 'CONTROLES REMOTO'
  },
  {
    value: 'RUEDAS O RULEMANES',
    label: 'RUEDAS O RULEMANES'
  },
  {
    value: 'SWICH DE GIRO DE BUTACA',
    label: 'SWICH DE GIRO DE BUTACA'
  },
]



const tecnico = [  
  {
    value: "Pablo",
    label: "Pablo",
  },
  {
    value: "Juan",
    label: "Juan",
  },
]

const garantia = [  
  {
    value: "no",
    label: "no garantia",
  },
  {
    value: "si",
    label: "garantia",
  },
]

export function JobForm() {

  const navigate = useNavigate(); 
  const [ job, setJob ] = useState({
    dispositivo: "",    
    observaciones: "",
    codigo: "",
    garantia: "", 
    tecnico: "", 
    remito: "",
    cuentaID: "",
      })
  const params = useParams();
  const dispatch = useDispatch();  
  const { user } = useAuth();
 
  const cuentas = useSelector(selectCuenta)

  

  return (
    <Box 
      margin={5} 
      maxWidth={800}
    >
       <Paper elevation={20} square={false} >
        <Box
          padding={2}
        >
          <Box ><Typography margin='4px' sx={{ fontSize: 21 }} color="primary" gutterBottom>
            Agregar nuevo trabajo </Typography></Box>

          <Formik
            initialValues={job}
            enableReinitialize
            onSubmit={async (values, actions) => {
              const cuentajob = params.id
              values.cuentaID = cuentajob
              //console.log(values)
              await createJobRequest(values);
              dispatch(addJob(values));

              actions.resetForm();
              actions.setSubmitting(false);
                      //navigate(`/cue`)
              navigate(`/cue/${params.id}`); 
            }}
          >
              {({
                  setFieldValue, 
                  isSubmitting, 
                  handleSubmit, 
                  handleChange, 
                  handleBlur,
                  values 
              }) => (
                <Form onSubmit={handleSubmit}>
                      <Box
                        m="40px"
                        display="grid"
                        gap="30px"
                      >
                        <TextField
                          id="outlined-select-currency"
                          select           
                          label="dispositivo"                       
                          onChange={handleChange}
                          value={values.dispositivo}
                          name="dispositivo"
                          sx={{ gridColumn: "span 4" }}                                              
                        >{dispositivo.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}  
                        </TextField>
                        <TextField
                          id="outlined-select-currency"
                          select           
                          label="garantia"                       
                          onChange={handleChange}
                          value={values.garantia}
                          name="garantia"
                          sx={{ gridColumn: "span 2" }}                                              
                        >{garantia.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}  
                        </TextField>
                        <TextField
                          id="outlined-select-currency"
                          select           
                          label="tecnico"                       
                          onChange={handleChange}
                          value={values.tecnico}
                          name="tecnico"
                          sx={{ gridColumn: "span 2" }}                                              
                        >{tecnico.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}  
                        </TextField>
                        <TextField
                          fullWidth
                          variant="filled"
                          type="text"
                          label="Remito"                       
                          onChange={handleChange}
                          value={values.remito}
                          name="remito"
                          sx={{ gridColumn: "span 4" }}                                              
                        />
                        <TextField
                          fullWidth
                          variant="filled"
                          type="text"
                          label="Observaciones"                       
                          onChange={handleChange}
                          value={values.observaciones}
                          name="observaciones"
                          sx={{ gridColumn: "span 4" }}                                              
                        />
                      </Box>
                      <Box
                        marginLeft={10}
                      >
                        <Button 
                          type='submit'
                          startIcon={<SaveOutlinedIcon/>} 
                          variant="outlined"
                          sx={{ mx: 22, my: 2 }}
                          >Guardar</Button>
                      </Box>
                  </Form>
              )
              }
          </Formik>
        </Box>
      </Paper>
    </Box>
  )
}