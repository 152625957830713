// https://www.chartjs.org/docs/latest/charts/line.html
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectCuenta } from '../../../../features/cuentas/cuentaSlide';  
import { usePosts } from '../../../../context/PostContext';
import { useAuth } from "../../../../context/AuthContext";
import { Box, Grid, Button, Paper, Typography } from "@mui/material";
import { GaugePage } from './Graph/GaugePage';
import { LineChart } from '@mui/x-charts/LineChart';
import { getPostRequest, getPotsRequest } from "../../../../api/post";
import { selectPost } from '../../../../features/post/postSlide';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  Chart as ChartJS,
  CategoryScale,  
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { Line } from 'react-chartjs-2';
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
);

export function BoxVolt({results}) {

  const [rowId, setRowId] = useState(null);
    const { posts, getPosts } = usePosts();
    const navigate = useNavigate();
    //const posts = useSelector(selectPost) 
    const cuentas = useSelector(selectCuenta);
    const cuentaResult = cuentas.filter(x => x._id === results)    
    const postResult = posts.filter(x => x.topiccuenta === cuentaResult[0].nroorden)   
    const voltR = postResult.filter(x => (x.topicevento === "vbat"  ))
    const LengthR = (voltR.length)
    const voltR1 = voltR.slice((LengthR,LengthR-1))
    
    
    //console.log(voltR2)
  //console.log('pData1', pData1)  
  
    const [chartData, setChartData]  = useState({
      labels: "",
      datasets: [
        {
        label: "",
        data: "",
      },
    ]
    });

    const [voltage, setVoltage] = useState({
      volt: 5,
    });   

    const Chart = async () => {      
      const postChart = await getPotsRequest();      
      const postChartData = postChart.data
           //console.log(postChartData)

      const cuentaResult = cuentas.filter(x => x._id === results)    
      const postResult = postChartData.filter(x => x.topiccuenta === cuentaResult[0].nroorden)  
      
      // Voltaje
      const voltR = postResult.filter(x => (x.topicevento === "vbat"  ))
      const LengthR = (voltR.length)
      const voltR2 = voltR.slice((LengthR,LengthR-10))
      
      // voltaje
      const VoltVar = postResult.filter(x => x.topicevento === 'vbat');
        const Volt = []
        const xVolt = []
        const Vfinal = VoltVar.length
        const Vinicio = VoltVar.length - 10
        for (let index = Vinicio; index < Vfinal; index++) {            
            Volt.push(VoltVar[index].message);
            xVolt.push(((new Date(VoltVar[index].createdAt)).getHours()).toString() + ':' 
                + ((new Date(VoltVar[index].createdAt)).getMinutes()).toString());
            //console.log(Volt)            
        }  
      
      // Corriente
      const AmpVar = postResult.filter(x => x.topicevento === 'ic');
        const Amp = []
        const xAmp = []
        const Afinal = AmpVar.length
        const Ainicio = AmpVar.length - 10 
        for (let index = Ainicio; index < Afinal; index++) {            
            Amp.push(AmpVar[index].message);
            xAmp.push(((new Date(AmpVar[index].createdAt)).getHours()).toString() + ':' 
                + ((new Date(AmpVar[index].createdAt)).getMinutes()).toString());
            //console.log(Amp, xAmp)            
        }
      
        setChartData({        
          labels: xVolt,        
          datasets: [{
            label: 'Voltaje',
            data: Volt,
            borderColor: 'pink',
            backgroundColor: [
              '#c8c5e0'            
            ],
            borderColor: '#4b39db',
          },
          {
            label: 'Amperaje',
            data: Amp,
            borderColor: 'orange',
            yAxisID: 'secondY',
            backgroundColor: [
              '#76ff03'            
            ],
            borderColor: '#52b202',
          }
        ]        
        })
    }

    const GaugeVolt = () => {
      const voltR = postResult.filter(x => (x.topicevento === "vbat"  ))
      const LengthR = (voltR.length)
      const voltR1 = voltR.slice((LengthR,LengthR-1))
      const volt = voltR1[0].message
      setVoltage({volt})

    }
    //console.log(voltage.volt)

    useEffect(() => {
      const timer = setInterval( async () => {
        Chart();
        GaugeVolt();
      }, 5000);
      return () => clearInterval(timer);
    }, []);


    var options = {
      maintainAspectRatio: false,
      scales: {
        y: {
          display: true,
          min: 20,
          max: 32
        },
        secondY: {
          position: 'right',
          display: true,
          min: 0,
          max: 3
        }
      },
      legend: {
        labels: {
          fontSize: 12,
        },
      },
      
        pointStyle: 'circle',
        pointRadius: 5,
        pointHoverRadius: 15
    }

    

    
  return (
    <Box
          height ={205} 
          width={715}          
        >
          <Paper elevation={0} square={false}>
            <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="flex-start"   
              >
                <Grid marginLeft={2}>
                  <Typography variant="h5" color='primary' paddingTop={1} gutterBottom>Voltaje de Baterias</Typography>
                  <Box
                      width={ 150}
                      height={ 150}  
                      marginTop={2}  
                      marginLeft={5}                       
                  >
                      <Paper elevation={0} square={false} >                                            
                          Volt:{voltage.volt}v.
                          <GaugePage voltaje = {voltage.volt}/>                        
                      </Paper>
                  </Box>
                </Grid>

              <Box
                height ={200} 
                width={350}          
                  >
                    <Line
                      data={chartData}
                      height={100}
                      options={options}
                      />
              </Box>  

              <Grid>
              <Box
                marginTop={5}
                marginRight={1}
              >
                <Box
                  marginTop={2}
                >
                  <Typography variant="subtitle2"> Valor:{LengthR}</Typography>
                </Box>
                <Box
                  marginTop={2}
                >
                  <Button 
                    variant="contained" 
                    size="small"
                    onClick={()=> navigate(`/gra10/${results}`)}   
                    
                     
                  >X</Button>
                </Box>
              </Box>
              </Grid>  



              </Grid>
            </Paper>
    </Box>
    
  )
}




  
  
  
  