import { createSlice } from '@reduxjs/toolkit';

const initialState = [
    {
        _id: "0000000000000000001",
        message: 10,
        topiccuenta: "cuenta1",
        topicevento: "cuenta1",
      },      
    ];
    
export const alarmSlice = createSlice({
    name: 'alarmsSlice',
    initialState,
        reducers: {
            addAlarm: (state, action) => {
                //console.log('action', action.payload); 
                let ixs = state.length-1;
                let ixa = action.payload.length;
                var rec = ixa-ixs;
                //console.log(rec, ixa, ixs)
                if (rec > 0) {
                    for (let i = ixs; i < ixa; i++) {
                        let postadd = action.payload
                        //console.log('estado addTask', postadd[i] );
                        //state.every(postadd[i]);
                        //state.push(postadd[i]); 
                        state.push(postadd[i]);
                    }
                }
            },
            
            editAlarm: (state, action) => {
                const { id, message, status, topic, estado, dia, hora, topiccuenta, topicevento, topicnombre } = action.payload;
                //console.log('action', action.payload);            
                const foundPost = state.find((post) => post._id === id);
                let item = JSON.parse(JSON.stringify(foundPost))   
                    foundPost.estado = estado;
                    foundPost.hora = hora;
            },  

            deleteAlarm: (state, action) => {
                console.log(action.payload)
                const { id, message, status, topic, estado, dia, hora, topiccuenta, topicevento, topicnombre } = action.payload;
                //const deletePost = state.filter(post => post.id !== id)
            },
            
            resetAlarm: ( state ) => 
                    initialState,
        }
})

export const { addAlarm, editAlarm, resetAlarm, deleteAlarm } = alarmSlice.actions;
export const selectAlarm = (state) => state.alarmsState;
export default alarmSlice.reducer

